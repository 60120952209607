import React from "react";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import InstallInstructions from "../components/InstallInstructions";

const Install = () => {
  return (
    <div className="min-h-full">
      <LoggedOutNavBar />
      <div className="overflow-hidden mx-auto max-w-6xl rounded-lg bg-gray-50 px-32">
        <InstallInstructions />
      </div>
    </div>
  );
};

export default Install;
