import React, { useState } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import LoggedInNavBarBrands from "../components/LoggedInNavBarBrands";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { FormSubmit } from "../utils/Utils";
import Spinner from "../components/Spinner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BrandAddCredit = () => {
  const [creditsRequested, setCreditsRequested] = useState(50);
  const [error, setError] = useState(null);
  const [apiError, setAPIError] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const { user } = useAuth();
  function setCredits(e) {
    const val = e.target.value;
    if (val < 50) {
      setError("You buy at least 50 credits");
    } else {
      setError(null);
    }
    setCreditsRequested(val);
  }
  async function initializePayment() {
    if (error) {
      return;
    }
    if (requesting) {
      return;
    }
    try {
      setRequesting(true);
      setAPIError(false);
      const response = await FormSubmit(
        "brands/initialize_buy_credit",
        {
          quantity: creditsRequested,
        },
        user.token
      );
      window.location = response.redirect_to;
    } catch (e) {
      console.log(e);
      setAPIError(true);
    } finally {
      setRequesting(false);
    }
  }
  const buttonText = requesting ? (
    <Spinner />
  ) : (
    `Buy ${creditsRequested} credits`
  );
  return (
    <>
      {user ? (
        <LoggedInNavBarBrands user={user} isAddCreditForm />
      ) : (
        <LoggedOutNavBar />
      )}
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 max-w-md">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      <li>You must purchase at least 50 credits</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          {apiError && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      <li>
                        An unknown error occurred. Please contact
                        support@hightide.link
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              How many credits do you want?
            </p>
            <p className="mt-2 text-xl tracking-tight text-gray-600 sm:text-xl">
              (1 credit = $1 USD)
            </p>
          </div>
          <div className="mt-4">
            <input
              type="number"
              name="number"
              min="50"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={creditsRequested}
              onChange={setCredits}
            />
          </div>
          <div className="flex flex-row items-center justify-center mt-4">
            <button
              disabled={!!error}
              type="button"
              className={classNames(
                error
                  ? "bg-gray-400 hover:bg-gray-500 focus-visible:outline-gray-600"
                  : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
              onClick={initializePayment}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandAddCredit;
