/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import HighTidePlistExample from "../images/HighTideLib-InfoPlist.png";
import { useAuth } from "../library/contexts/AuthProvider";
import { getRequest, joinClassNames } from "../utils/Utils";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

const InstallInstructions = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [apps, setApps] = useState([]);
  const [codeSampleType, setCodeSampleType] = useState("objective-c");

  useEffect(() => {
    const loadApps = async () => {
      const brandId = user?._id;
      if (!brandId || !user) {
        return;
      }

      try {
        setLoading(true);

        const response = await getRequest(`brands/apps`, user.token);

        setApps(response.apps);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadApps();
  }, [user]);
  const copyAppTokenToClipboard = (token) => {
    navigator.clipboard.writeText(token);
  };
  // @ts-ignore
  return (
    <div className="overflow-hidden rounded-lg bg-white mb-20 px-32 py-12">
      <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
        Install HighTide SDK
      </div>
      <div className="text-sm leading-5 font-normal text-gray-500 mb-6">
        To install the HighTideLib CocoaPod, follow the steps below:
      </div>
      <ol className="items-center w-full space-y-4">
        <li className="flex items-start text-gray-700 space-x-4">
          <span className="flex items-center justify-center w-12 h-12 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            1
          </span>
          <span>
            <h3 className="font-medium leading-tight">
              Add the &apos;HighTideLib&apos; to your projects Podfile
            </h3>
            <div className="bg-gray-100 p-4 mt-4 mb-8">
              <pre style={{ whiteSpace: "pre-wrap" }}>
                <code
                  className="text-gray-800"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  <div className="mb-0">
                    pod &apos;HighTideLib&apos;, :git =&gt;
                    git@github.com:/turntable-fm/HighTideLib.git
                  </div>
                </code>
              </pre>
            </div>
          </span>
        </li>
        <li className="flex items-start text-gray-700 space-x-4">
          <span className="flex items-center justify-center w-12 h-12 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            2
          </span>
          <span>
            <h3 className="font-medium leading-tight">
              Install the pod by running the following command:
            </h3>
            <div className="bg-gray-100 p-4 mt-4 mb-8">
              <pre style={{ whiteSpace: "pre-wrap" }}>
                <code
                  className="text-gray-800"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  <div className="mb-0">$ pod install</div>
                </code>
              </pre>
            </div>
          </span>
        </li>
        <li className="flex items-start text-gray-700 space-x-4">
          <span className="flex items-center justify-center w-12 h-12 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            3
          </span>
          <div className="flex flex-col">
            <h3 className="font-medium leading-tight">
              Add your &apos;HighTideAppToken&apos; to your Info.plist file
            </h3>
            <p className="text-md text-gray-400">
              The HighTideAppToken uniquely identifies your application and
              associates installs with your app. In XCode, configure your app to
              use your HighTideAppToken by adding a new key/value pair to your
              app&apos;s Info.plist file or to the Info section of each target
              defined for your app.
            </p>
            <img src={HighTidePlistExample} className={"my-4"} />
            {!loading && apps.length > 0 && (
              <div>
                <p className="text-md text-gray-400">
                  The HighTideAppToken for your app is assigned when you
                  register the app initially. You can find the HighTideAppToken
                  for each app you created below:
                </p>
                <ul className="mt-4">
                  {apps.map((app) => (
                    <li className="my-4 text-sm text-gray-600" key={app._id}>
                      <div className="flex flex-row items-center">
                        <span>
                          {app.name} {app.platform} - {app.token}
                        </span>
                        <button
                          type="button"
                          onClick={() => copyAppTokenToClipboard(app.token)}
                          className="ml-1 flex items-center justify-start text-indigo-600 active:text-indigo-200"
                        >
                          <ClipboardDocumentListIcon
                            className="text-sm w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </li>
        <li className="flex items-start text-gray-700 space-x-4">
          <span className="flex items-center justify-center w-12 h-12 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            4
          </span>
          <span>
            <h3 className="font-medium leading-tight">
              Import the HighTideLib
            </h3>
            <p className="text-md text-gray-400">
              In your AppDelegate or SceneDelegate file, import the HighTideLib
              module using the following statement:
            </p>
            <div className="mt-2 flex flex-row gap-x-2">
              <button
                type="button"
                className={joinClassNames(
                  codeSampleType === "swift" ? "bg-gray-200" : "bg-gray-50",
                  "inline-flex justify-center rounded-s px-3 py-2 text-xs font-semibold text-gray-700 hover:bg-gray-200"
                )}
                onClick={() => setCodeSampleType("swift")}
              >
                Swift
              </button>
              <button
                type="button"
                className={joinClassNames(
                  codeSampleType === "objective-c"
                    ? "bg-gray-200"
                    : "bg-gray-50",
                  "inline-flex justify-center rounded-s px-3 py-2 text-xs font-semibold text-gray-700 hover:bg-gray-200"
                )}
                onClick={() => setCodeSampleType("objective-c")}
              >
                Objective-C
              </button>
            </div>
            {codeSampleType === "swift" && (
              <div className="bg-gray-100 p-4 mt-4 mb-8">
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  <code
                    className="text-gray-800"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <div className="mb-0">import HighTideLib</div>
                  </code>
                </pre>
              </div>
            )}
            {codeSampleType === "objective-c" && (
              <div className="bg-gray-100 p-4 mt-4 mb-8">
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  <code
                    className="text-gray-800"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <div className="mb-0">
                      #import &lt;HighTideLib/HighTideLib-Swift.h&gt;
                    </div>
                  </code>
                </pre>
              </div>
            )}
          </span>
        </li>
        <li className="flex items-start text-gray-700 space-x-4">
          <span className="flex items-center justify-center w-12 h-12 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
            5
          </span>
          <span>
            <h3 className="font-medium leading-tight">
              Add install tracking code
            </h3>
            <p className="text-md text-gray-400">
              In your AppDelegate or SceneDelegate file, implement the
              applicationDidBecomeActive or sceneDidBecomeActive become active
              code.
            </p>
            <div className="mt-2 flex flex-row gap-x-2">
              <button
                type="button"
                className={joinClassNames(
                  codeSampleType === "swift" ? "bg-gray-200" : "bg-gray-50",
                  "inline-flex justify-center rounded-s px-3 py-2 text-xs font-semibold text-gray-700 hover:bg-gray-200"
                )}
                onClick={() => setCodeSampleType("swift")}
              >
                Swift
              </button>
              <button
                type="button"
                className={joinClassNames(
                  codeSampleType === "objective-c"
                    ? "bg-gray-200"
                    : "bg-gray-50",
                  "inline-flex justify-center rounded-s px-3 py-2 text-xs font-semibold text-gray-700 hover:bg-gray-200"
                )}
                onClick={() => setCodeSampleType("objective-c")}
              >
                Objective-C
              </button>
            </div>
            {codeSampleType === "objective-c" && (
              <div className="bg-gray-100 p-4 mt-8 mb-8">
                <pre style={{ whiteSpace: "pre-wrap" }}>
                  <code
                    className="text-gray-800"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <div className="mb-1">
                      func applicationDidBecomeActive(_ application:
                      UIApplication) &#123;
                    </div>
                    <div className="mb-1 ml-4">
                      [HighTideManager trackInstall];
                    </div>
                    <div className="mb-1">&#125;</div>
                  </code>
                </pre>
              </div>
            )}
            {codeSampleType === "swift" && (
              <>
                <div className="bg-gray-100 p-4 mt-8 mb-4">
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    <code
                      className="text-gray-800"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <div className="mb-1">
                        func applicationDidBecomeActive(_ application:
                        UIApplication) &#123;
                      </div>
                      <div className="mb-1 ml-4">
                        HighTideManager.trackInstall()
                      </div>
                      <div className="mb-1">&#125;</div>
                    </code>
                  </pre>
                </div>
                <p className="text-sm text-gray-400">Or</p>
                <div className="bg-gray-100 p-4 mt-4 mb-8">
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    <code
                      className="text-gray-800"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <div className="mb-1">
                        func sceneDidBecomeActive(_ scene: UIScene) &#123;
                      </div>
                      <div className="mb-1 ml-4">
                        HighTideManager.trackInstall()
                      </div>
                      <div className="mb-1">&#125;</div>
                    </code>
                  </pre>
                </div>
              </>
            )}
          </span>
        </li>
      </ol>
    </div>
  );
};

export default InstallInstructions;
