import React from "react";
import LoggedOutNavBar from "../components/LoggedOutNavBar";

export default function Success() {
  return (
    <>
      <LoggedOutNavBar />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-10">
        <div className="flex flex-col justify-center items-center mt-32 mb-32">
          <img src="/success.svg" className="h-auto" />
          <h2 className="max-w-lg mt-3.5 text-2xl font-medium leading-normal text-gray-900 lg:text-3xl">
            Your account is under review
          </h2>
          <p className="max-w-md text-center mt-2 text-sm text-gray-500">
            {
              "We'll notify you shortly when your account has been reviewed by the Hightide team."
            }
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-slate-950 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Back Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
