import React, { useEffect, useRef, useState } from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useAuth } from "../library/contexts/AuthProvider";
import { validText } from "../utils/Utils";
import Alert from "../components/Alert";
import LoggedInNavBarAmbassadors from "../components/LoggedInNavBarAmbassadors";
import PasswordChangeForm from "../components/ChangePasswordForm";

const subNavigation = [
  {
    name: "My Profile",
  },
  {
    name: "Change Password",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AmbassadorProfile = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { user, updateAmbassador } = useAuth();
  const fileUploadRef = useRef(null);
  const [updatedUser, setUpdatedUser] = useState({});
  const [saveComplete, setSaveComplete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    setUpdatedUser(user);
  }, [user]);

  const onChangeHandler = (e) => {
    setSaveComplete(false);
    setUpdatedUser(
      Object.assign({}, updatedUser, {
        [e.target.name]: e.target.value,
      })
    );
  };
  const formSubmitted = async (e) => {
    e.preventDefault();

    try {
      var error = false;

      if (!updatedUser.avatar) {
        error = "Please add an avatar";
      }
      if (!validText(updatedUser.alias)) {
        error = "Please enter your first name or an alias";
      }

      if (!validText(updatedUser.about)) {
        error = "Please include about yourself";
      }
      if (!validText(updatedUser.lastName)) {
        error = "Please include your last name";
      }
      if (!validText(updatedUser.firstName)) {
        error = "Please include your first name";
      }

      if (error) {
        window.scrollTo(0, 0);
        setHasError(true);
        setErrorMessage(error);
      } else {
        setHasError(false);

        try {
          await updateAmbassador(updatedUser);
          setSaveComplete(true);
        } catch (err) {
          setHasError(true);
          setErrorMessage(
            "We could not update your profile. Please log out and back in to try and fix this."
          );
        }
        window.scrollTo(0, 0);
      }
    } catch (err) {
      window.scrollTo(0, 0);
      setHasError(true);
      setErrorMessage(err.message);
    }
  };
  const handleAvatar = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];

      const formData = new FormData();
      formData.append("file", img);

      try {
        const URL = `${process.env.REACT_APP_HIGHTIDE_API_URL}avatar`;
        const response = await fetch(URL, {
          method: "POST",
          body: formData,
        });
        let data = await response.json();
        setUpdatedUser(Object.assign({}, updatedUser, { avatar: data.url }));
      } catch (err) {
        setHasError(true);
        setErrorMessage(err.message);
        console.log("error", err);
      }
    }
  };
  const cancelClicked = () => {
    setUpdatedUser(user);
    setHasError(false);
    window.scrollTo(0, 0);
  };
  return (
    <div className="min-h-full">
      <LoggedInNavBarAmbassadors />
      <div className="overflow-hidden mx-auto max-w-6xl rounded-lg bg-gray-50 px-32">
        <div className="my-10">
          {subNavigation.map((item, index) => (
            <button
              key={index}
              type="button"
              className={classNames(
                index === selectedTabIndex ? "bg-gray-100" : "",
                "inline-flex justify-center rounded-md px-3 py-2 mr-14 text-sm font-semibold text-gray-700 hover:bg-gray-200"
              )}
              onClick={() => {
                setSelectedTabIndex(index);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
        {selectedTabIndex === 1 && (
          <div className="overflow-hidden rounded-lg bg-white mb-20 px-56 py-12">
            <PasswordChangeForm />
          </div>
        )}
        {selectedTabIndex === 0 && (
          <>
            {hasError && (
              <Alert
                text={
                  errorMessage ||
                  "Sorry, there was an error signing in, please try again"
                }
              />
            )}
            {saveComplete && (
              <Alert
                type="success"
                text="Your profile was successfully saved"
              />
            )}
            <div className="overflow-hidden rounded-lg bg-white mb-20 px-56 py-12">
              <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Profile
              </div>
              <div className="text-sm leading-5 font-normal text-gray-500 mb-6">
                This information will be displayed publicly so be careful what
                you share.
              </div>
              <label className="text-sm leading-5 font-medium text-gray-700">
                First name or alias
              </label>
              <div className="mt-2">
                <input
                  id="alias"
                  name="alias"
                  type="text"
                  autoComplete="username"
                  required
                  className="block w-full rounded-md border-0 mb-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={updatedUser?.alias}
                  onChange={onChangeHandler}
                />
              </div>
              <label
                htmlFor={"about"}
                className="text-sm leading-5 font-medium text-gray-700"
              >
                About
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block w-full rounded-md border-0 mb-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                  defaultValue={updatedUser?.about}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="text-sm leading-5 mb-6 font-normal text-gray-500">
                Write a few sentences about yourself.
              </div>
              <label className="text-sm leading-5 font-medium text-gray-700">
                Avatar
              </label>
              <div className="mt-2 flex items-center gap-x-3 mb-8">
                {updatedUser?.avatar ? (
                  <div className="w-16 h-16">
                    <img
                      src={updatedUser?.avatar}
                      alt="Profile Image"
                      className="object-cover w-full h-full rounded-full"
                    />
                  </div>
                ) : (
                  <UserCircleIcon
                    className="h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                )}
                <button
                  type="button"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Upload
                </button>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  ref={fileUploadRef}
                  accept="image/png, image/jpeg"
                  onChange={handleAvatar}
                />
              </div>
              <div className="bg-gray-200 mb-8 h-px" />
              <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Personal information
              </div>
              <div className="text-sm leading-5 font-normal text-gray-500 mb-6">
                Use a permanent address where you can receive mail.
              </div>
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="firstName"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={updatedUser?.firstName}
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="lastName"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={updatedUser?.lastName}
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="username"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50"
                      value={updatedUser?.email}
                      onChange={onChangeHandler}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-200 my-8 h-px" />
              <div className="flex flex-row justify-end">
                <button
                  type="submit"
                  className="flex justify-center rounded-md bg-white px-4 py-2 mr-3 text-sm font-semibold text-grey-700 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={cancelClicked}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex justify-center rounded-md bg-black px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={formSubmitted}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AmbassadorProfile;
