import React, { useState, useEffect } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import { getRequest } from "../utils/Utils";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import LoggedInNavBarBrands from "../components/LoggedInNavBarBrands";

const JobDashboard = () => {
  const { user } = useAuth();
  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);

  const { jobId } = useParams();

  useEffect(() => {
    const fetchJob = async () => {
      if (!jobId || !user) {
        return;
      }

      try {
        setLoading(true);

        getRequest(`get_job_with_id/${jobId}`, user.token).then((response) => {
          setJob(response.job);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchJob();
  }, [jobId, user]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const renderStatusLabel = (status) => {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
        }}
        className={statusClasses[status]}
      >
        {status}
      </p>
    );
  };

  const statusClasses = {
    active:
      "text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
    completed:
      "text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
    archived:
      "text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
    paused:
      "text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
  };

  function EditButton(props) {
    return (
      <button
        type="button"
        onClick={props.onClick}
        className="inline-flex items-center px-4 py-2 bg-blue-500 border border-transparent rounded-md font-semibold text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={props.disabled}
      >
        Edit
      </button>
    );
  }

  return (
    <div>
      {/* Navigation */}
      {user?.userType === "brand" ? <LoggedInNavBarBrands user={user} /> : null}

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {job.title}
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-600">
                {job.description}
              </p>
              <div
                style={{
                  margin: 32,
                }}
              >
                <p>
                  {/* <span>App Url:</span> */}
                  <a
                    style={{
                      color: "blue",
                    }}
                    href={job.appUrl}
                  >
                    {job.appUrl}
                  </a>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 64,
                  cursor: "default",
                }}
              >
                <div
                  style={{
                    width: 120,
                  }}
                >
                  {renderStatusLabel(job.status)}
                </div>
              </div>
              <EditButton
                onClick={() => {
                  window.location.href = `/brands/edit_job/${jobId}`;
                }}
              />
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Budget
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  ${(job.budgetCents ? job.budgetCents : 0) / 100}
                </dd>
              </div>

              <div className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Price
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  ${job.priceCents / 100 || 0}
                </dd>
              </div>

              <div className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Impressions
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  {job.impressions || 0}
                </dd>
              </div>

              <div className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-600">
                  Installs
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                  {job.installs || 0}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDashboard;
