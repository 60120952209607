import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";

import LoggedOutNavBar from "../components/LoggedOutNavBar";
import { getRequest } from "../utils/Utils";

const AmbassadorProfile = () => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [ambassador, setAmbassador] = useState(true);
  const { ambassadorId } = useParams();

  useEffect(() => {
    const getAmbassadorInfo = async () => {
      try {
        const resp = await getRequest(`ambassador/${ambassadorId}`);
        setAmbassador(resp.ambassador);
      } catch (e) {
        console.log(`Error fetching ambassador:${ambassadorId}, err:${e}`);
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    if (loading && ambassadorId) {
      getAmbassadorInfo();
    }
  }, [ambassadorId]);

  return (
    <div className="min-h-full">
      <LoggedOutNavBar />
      {hasError && (
        <div className="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-xl">
            <div className="flex">
              <div>
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Something went wrong
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                          <li>
                            If this error continues, please contact{" "}
                            <strong>help@hightide.link</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-xl">
            <div className="flex">
              <div>
                <h4 className="text-lg font-bold">Loading...</h4>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hasError && !loading && (
        <div className="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-xl">
            <div className="flex">
              <div className="mr-4 flex-shrink-0 self-center">
                <img
                  className="inline-block h-14 w-14 rounded-full"
                  src={ambassador.avatar}
                  alt=""
                />
              </div>
              <div>
                <h4 className="text-lg font-bold">{`${ambassador.firstName} ${ambassador.lastName}`}</h4>
                <p className="mt-1">{ambassador.about}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmbassadorProfile;
