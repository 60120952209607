import React, { useState, useEffect } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import LoggedInNavBarBrands from "../components/LoggedInNavBarBrands";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import Spinner from "../components/Spinner";
import { FormSubmit, getRequest } from "../utils/Utils";

// NOTE: we spec'd that a Brand must buy spend at least $50 USD per
// Job
const MIN_BUDGET_CENTS = 5000;

const BrandCreateJob = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [currentBalance, setCurrentBalance] = useState(0);

  useEffect(() => {
    if (user && user?.apps?.length > 0) {
      setFormData({ ...formData, app: user.apps[0]._id });
    }
    async function fetchBalance() {
      if (!user) {
        return;
      }

      try {
        const ret = await getRequest("brands/balance", user.token);
        setCurrentBalance(ret.balanceCents / 100);
      } catch (e) {
        console.log("Error fetching credits: ", e);
      } finally {
        setLoading(false);
      }
    }
    fetchBalance();
  }, [user]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    appUrl: "",
    platform: "iOS",
    budgetCents: MIN_BUDGET_CENTS,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "budgetCents") {
      if (!value) {
        setFormData({ ...formData, [name]: "" });
      } else {
        setFormData({ ...formData, [name]: Math.floor(value * 100) });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "appUrl") {
      let strippedValue = value;
      if (value.startsWith("https://")) {
        strippedValue = value.slice(8);

        setFormData({ ...formData, [name]: strippedValue });
      } else if (value.startsWith("http://")) {
        strippedValue = value.slice(7);

        setFormData({ ...formData, [name]: strippedValue });
      }
    }
    console.log(JSON.stringify(formData));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const authToken = user?.token;

    const data = {
      ...formData,
      appUrl: `https://${formData.appUrl}`,
    };
    try {
      const response = await FormSubmit("create_job", data, authToken);
      console.log(response);

      // take the user to the brand dashboard
      window.location.href = "/dashboard/brand";
      // optionally display a success message to the user
    } catch (error) {
      console.error(error);
      // optionally display an error message to the user
    }
  };
  const insufficientFunds = currentBalance * 100 < MIN_BUDGET_CENTS;
  const budgetFmt = formData.budgetCents ? formData.budgetCents / 100 : "";
  const expectedInstallsNum = formData.budgetCents
    ? Math.floor(formData.budgetCents / 100)
    : 0;
  const budgetRendered = formData.budgetCents ? formData.budgetCents / 100 : 0;

  return (
    <>
      {user ? (
        <LoggedInNavBarBrands user={user} isNewJobForm />
      ) : (
        <LoggedOutNavBar />
      )}
      <div className="mx-auto max-w-xl py-6 sm:px-6 lg:px-8">
        {loading && <Spinner color="indigo" />}
        {!loading && insufficientFunds && (
          <>
            <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-6">
              Insufficient Funds
            </h1>
            <p>
              You need to add more credits to your balance to create new jobs.
            </p>
            <a href="/dashboard/brand/add_credit">
              <button
                type="button"
                className="mr-4 mt-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Add Credits
              </button>
            </a>
          </>
        )}
        {!loading && !insufficientFunds && (
          <>
            <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-6">
              Create a New Job
            </h1>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Title
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    onChange={handleInputChange}
                    value={formData.title}
                    className="block w-full rounded-md border-gray-300 shadow-sm px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Enter the app title"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    onChange={handleInputChange}
                    value={formData.description}
                    rows={3}
                    className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter a brief description of the app"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="appUrl"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  App Store Url
                </label>
                <div className="mt-2">
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        https://
                      </span>
                      <input
                        type="text"
                        name="appUrl"
                        id="appUrl"
                        required
                        title="A URL from the iOS or Android app stores."
                        pattern="^(?=.*(?:apps\.apple\.com|play\.google\.com)).*$"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder={"apps.apple.com/myApp"}
                        value={`${formData.appUrl}`}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="text-sm leading-5 font-medium text-gray-700">
                  Platform
                </label>
                <div className="mt-2">
                  <select
                    id="platform"
                    name="platform"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleInputChange}
                    value={formData.platform}
                  >
                    <option key={"android"} value={"android"}>
                      Android
                    </option>
                    <option key={"ios"} value={"ios"}>
                      iOS
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="budget"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Total Budget
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name="budgetCents"
                    id="budgetCents"
                    max={currentBalance}
                    min={MIN_BUDGET_CENTS / 100}
                    onChange={handleInputChange}
                    value={budgetFmt}
                    step="0.01"
                    placeholder="Enter your total budget"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <p className="mt-2 text-sm">
                  (Your budget has to be lower than your current balance of $
                  {currentBalance})
                </p>
              </div>
              <div className="flex justify-end">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Are you sure?
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      <p>
                        By creating this job, Ambassadors will start advertising
                        your app immediately. They will get paid $1-per-install
                        (minus fees) and you can expect to get{" "}
                        <strong>{expectedInstallsNum} installs</strong> at your
                        specified budget of ${budgetRendered}
                      </p>
                    </div>
                    <div className="mt-5 text-right">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 disabled:bg-gray-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        style={{
                          opacity: formData.budgetCents < 1 ? 0.5 : 1,
                          cursor:
                            formData.budgetCents < 1
                              ? "not-allowed"
                              : "pointer",
                        }}
                        disabled={user.disabled || formData.budgetCents < 1}
                      >
                        Yes, create job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default BrandCreateJob;
