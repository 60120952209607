import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../models/User";
import { FormSubmit, getRequest } from "../../utils/Utils.js";

/**
 * Context for storing and modifying user state
 */
export type AuthContextType = {
  user: User | null;
  updateAmbassador: (data: User) => void;
  updateBrand: (data: User) => void;
  registerApp: (user: User, app: any) => void;
  updateApp: (user: User, app: any) => void;
  storeUser: (data: User) => void;
  redirectToDashboard: () => void;
  signout: () => void;
  hasLocalKey: () => boolean;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  updateAmbassador: () => {},
  updateBrand: () => {},
  registerApp: () => {},
  updateApp: () => {},
  storeUser: () => {},
  redirectToDashboard: () => {},
  signout: () => {},
  hasLocalKey: () => false,
});

const USER_KEY = "hightide_user";
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  function storeUser(data: User) {
    localStorage.setItem(USER_KEY, JSON.stringify(data));
    setUser(data);
  }
  async function updateAmbassador(data: User) {
    const token = data.token;
    // @ts-ignore
    delete data.token;

    const savedUser = await FormSubmit("ambassadors", data, token, "PUT");
    savedUser.token = token;
    localStorage.setItem(USER_KEY, JSON.stringify(savedUser));
    setUser(savedUser);
  }
  async function updateBrand(data: User) {
    const token = data.token;
    // @ts-ignore
    delete data.token;

    const savedUser = await FormSubmit("brands", data, token, "PUT");
    savedUser.token = token;

    localStorage.setItem(USER_KEY, JSON.stringify(savedUser));
    setUser(savedUser);
  }

  async function refreshAmbassador(token: string) {
    const response = await getRequest("ambassador/me", token);
    if (response.me) {
      storeUser({ ...response.me, token: token, userType: "ambassador" });
    }
  }

  async function registerApp(user: User, app: any) {
    try {
      const response = await FormSubmit("app_register", app, user.token);

      if (response.success) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        setUser(user);

        return { success: true };
      } else {
        return {
          success: false,
          error_message: "Sorry, an error occurred.  Please try again.",
        };
      }
    } catch (e: any) {
      return { success: false, error_message: e.message };
    }
  }
  async function updateApp(user: User, app: any) {
    try {
      const response = await FormSubmit(
        `app_register/${app._id}`,
        app,
        user.token,
        "PUT"
      );

      if (response.success) {
        return { success: true };
      } else {
        return {
          success: false,
          error_message: "Sorry, an error occurred.  Please try again.",
        };
      }
    } catch (e: any) {
      return { success: false, error_message: e.message };
    }
  }
  function redirectToDashboard() {
    const userType = user?.userType;
    if (!userType) {
      navigate("/signin");
    } else if (userType === "ambassador") {
      navigate("/dashboard/ambassador");
    } else {
      navigate("/dashboard/brand");
    }
  }

  function clearUser() {
    localStorage.removeItem(USER_KEY);
    setUser(null);
  }

  function hasLocalKey() {
    const persisted = localStorage.getItem(USER_KEY);
    return persisted ? true : false;
  }

  async function signout() {
    try {
      if (user) {
        await FormSubmit("signout", {}, user.token);
        clearUser();
      }
    } catch (e) {
      clearUser();
      console.log(e);
    }

    navigate("/signin");
  }

  useEffect(() => {
    const persisted = localStorage.getItem(USER_KEY);
    if (persisted) {
      const currentUser = JSON.parse(persisted);
      setUser(currentUser);

      if (currentUser && currentUser.userType == "ambassador") {
        refreshAmbassador(currentUser.token);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        updateAmbassador,
        updateBrand,
        registerApp,
        updateApp,
        storeUser,
        redirectToDashboard,
        hasLocalKey,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext<AuthContextType>(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
