import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import { getShareLink } from "../utils/Utils";
import {
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  HeartIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export function AcceptedJobWalkthrough({
  linkCode,
  onClose,
}: {
  linkCode: string | undefined;
  onClose: () => void;
}) {
  const { user } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (linkCode) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user, linkCode]);

  const copyShareLink = () => {
    navigator.clipboard.writeText(getShareLink(linkCode));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 max-w-4xl md:w-full">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="bg-white py-24 sm:py-24">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Now what?
                      </h2>
                      <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <button
                              type="button"
                              onClick={copyShareLink}
                              className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                            >
                              <ClipboardDocumentListIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </button>
                            Copy the Share Link
                          </dt>
                          <dd className="mt-1 text-base leading-7 text-gray-600">
                            <input
                              type="url"
                              name="link"
                              id="link"
                              className="grow rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full"
                              value={getShareLink(linkCode)}
                              readOnly={true}
                            />
                            <button
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  getShareLink(linkCode)
                                )
                              }
                              className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block mt-2"
                            >
                              Copy!
                            </button>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                              <PencilSquareIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </div>
                            Create some content!
                          </dt>
                          <dd className="mt-1 text-base leading-7 text-gray-600">
                            Make some content and insert the link into your
                            content.
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                              <HeartIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </div>
                            Users view your content!
                          </dt>
                          <dd className="mt-1 text-base leading-7 text-gray-600">
                            When users view your content, make sure they can
                            access your link!
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                              <CurrencyDollarIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </div>
                            Get Paid!
                          </dt>
                          <dd className="mt-1 text-base leading-7 text-gray-600">
                            When users install the brand&apos;s app from your
                            content, check your dashboard to see the install
                            numbers and get paid for your work!
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
