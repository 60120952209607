import React from "react";
import Logo from "../images/Logo@3x.png";
import HeroImage from "../images/linkExpired.svg";

export default function LinkExpired() {
  return (
    <div style={{ flex: 1 }} className="flex flex-col md:flex-row">
      <div style={{ flex: 1 }} className="w-full md:w-1/2 bg-white">
        {/* Content for the left container */}
        <div
          style={{
            flex: 1,
            padding: 64,
          }}
        >
          <div>
            <img className="h-8 w-auto" src={Logo} alt="" />
          </div>

          <div style={{ marginTop: "20%" }}>
            <div
              style={{
                color: "#0178FF",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: 14,
              }}
            >
              Link Expired
            </div>
            <div
              style={{
                color: "#111827",
                fontSize: 48,
                fontWeight: 800,
              }}
            >
              Your link has expired
            </div>
            <div
              style={{
                color: "#6B7280",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              This link is expired but did you know you could sign up to be an
              ambassador and make money by sharing links like these?
            </div>
            <a href="/ambassador_signup">
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  width: "fit-content",
                  padding: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  borderRadius: 8,
                  marginTop: 32,
                  fontSize: 16,
                }}
              >
                Become an Ambassador
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#0178FF",
        }}
        className="w-full md:w-1/2"
      >
        {/* Content for the right container */}
        <div
          style={{
            flex: 1,
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: 64,
            }}
          >
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-white sm:text-6xl">
              The ultimate app promotion ecosystem
            </h1>
          </div>
          <img
            style={{
              width: "100%",
              margin: "auto",
            }}
            src={HeroImage}
          />
        </div>
      </div>
    </div>
  );
}
