import React from "react";
import { useState, useEffect } from "react";

import Alert from "./Alert";
import { FormSubmit } from "../utils/Utils";
import ForgotPassword from "./ForgotPassword";
import { useAuth } from "../library/contexts/AuthProvider";

import Logo from "../images/Logo@3x.png";

const SignInForm = ({ forgotPassword = false }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [notApproved, setNotApproved] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(forgotPassword);
  const { user, storeUser, redirectToDashboard } = useAuth();
  const formSubmitted = async (e) => {
    e.preventDefault();

    try {
      const ret = await FormSubmit("signinBrand", {
        email: email,
        password: password,
      });
      if (ret && ret.success) {
        const userFields = {
          token: ret.token,
          ...ret.user,
          userType: ret.userType,
        };
        storeUser(userFields);
        redirectToDashboard();
      } else if (ret.reason == "not approved") {
        setNotApproved(true);
      } else {
        setHasError(true);
      }
    } catch (e) {
      setHasError(true);
    }
  };

  const clickForgotPassword = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
  };

  const onCloseForgotPassword = () => {
    setShowForgotPassword(false);
  };
  useEffect(() => {
    if (user) {
      redirectToDashboard();
    }
  }, [redirectToDashboard, user]);
  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Hightide" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your brand account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            {notApproved && (
              <Alert text="You have not been approved to use Hightide yet." />
            )}
            {hasError && (
              <Alert text="Sorry, there was an error signing in, please try again" />
            )}
            <form className="space-y-6" onSubmit={formSubmitted}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="username"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="#"
                    onClick={clickForgotPassword}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </a>
                </div>
                <ForgotPassword
                  show={showForgotPassword}
                  onClose={onCloseForgotPassword}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div className="text-sm">
              <br />
              <a
                href="/signinAmbassador"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Sign in to your ambassador account
              </a>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">Or</span>
                </div>
              </div>

              <div className="mt-2 grid grid-cols-2 gap-3">
                <a
                  href="/ambassador_signup"
                  className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 my-4 text-xs text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                >
                  Ambassador Sign Up
                </a>
                <a
                  href="/brand_signup"
                  className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 my-4 text-xs text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                >
                  Brand Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
