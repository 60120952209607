import React from "react";

export default function NotFound() {
  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h2 className="mt-6 text-4xl font-extrabold text-gray-900">
            404 - Page Not Found
          </h2>
        </div>
        <div className="flex justify-center">
          <img
            className="w-1/2 h-1/2"
            src="/undraw_lost.png"
            alt="Lost online illustration"
          />
        </div>
        <div className="rounded-md">
          <p className="text-center mt-2 text-gray-600">
            Oops! The page you&apos;re looking for does not exist.
          </p>
        </div>
        <div className="flex justify-center mt-6">
          <a
            href="/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
}
