import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getRequest } from "../utils/Utils";
import LinkExpired from "./LinkExpired";
import NotFound from "./NotFound";

export default function JobLink() {
  const location = useLocation();
  const [error, setError] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);

  const getLink = async () => {
    const linkId = location.pathname.split("/")[1];

    try {
      getRequest(`link/${linkId}`)
        .then((response) => {
          if (response.link) {
            window.location.href = response.link;
          } else {
            setError(true);
          }
        })
        .catch((response) => {
          if (
            response?.reason === "inactive" ||
            response?.reason === "balance"
          ) {
            setLinkExpired(true);
          } else {
            setError(true);
          }
        });
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    getLink();
  }, []);

  if (linkExpired) {
    return <LinkExpired />;
  }
  return error ? <NotFound /> : <div>Loading...</div>;
}
