import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Alert from "./Alert";
import {
  FormSubmit,
  validText,
  validEmail,
  validWebsite,
  validPassword,
} from "../utils/Utils";
import { useAuth } from "../library/contexts/AuthProvider";

import { UserCircleIcon } from "@heroicons/react/24/solid";

// eslint-disable-next-line complexity
export default function SignUpForm(props) {
  const [alias, setAlias] = useState("");
  const [about, setAbout] = useState("");
  const [avatar, setAvatar] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const { user, redirectToDashboard } = useAuth();

  const [hasError, setHasError] = useState(false);
  const fileUploadRef = useRef(null);
  const navigate = useNavigate();

  // eslint-disable-next-line complexity, sonarjs/cognitive-complexity
  const formSubmitted = async (e) => {
    e.preventDefault();

    try {
      var error = false;

      if (!terms) {
        error = "Please review and accept our Terms of Service";
      }
      if (!avatar) {
        error = "Please add an avatar";
      }
      if (!props.brand && !validText(alias)) {
        error = "Please enter your first name or an alias";
      }
      if (props.brand && !validWebsite(website)) {
        error = "Please enter a valid website";
      }
      if (props.brand && !validText(name)) {
        error = "Please include your brand's name";
      }
      if (!validText(about)) {
        error =
          "Please include about yourself and have it no longer than 250 characters";
      }
      if (!validText(lastName)) {
        error = "Please include your last name";
      }
      if (!validText(firstName)) {
        error = "Please include your first name";
      }
      if (!validEmail(email)) {
        error = "Please include a valid email";
      }
      if (!validPassword(password)) {
        error =
          "Your password must be at least 8 characters and contain a capital letter, number and symbol";
      }

      if (error) {
        window.scrollTo(0, 0);
        setHasError(error);
      } else {
        setHasError(false);

        const ret = await FormSubmit("signup", {
          alias: alias,
          about: about,
          avatar: avatar,
          name: name,
          firstName: firstName,
          lastName: lastName,
          website: website,
          email: email,
          password: password,
          brand: props.brand,
        });
        if (ret && ret.success) {
          navigate("/success");
        } else {
          window.scrollTo(0, 0);
          if (ret.errors[0]?.msg) {
            setHasError(ret.errors[0].msg);
          } else {
            setHasError(true);
          }
        }
      }
    } catch (e) {
      window.scrollTo(0, 0);
      setHasError(e.message);
    }
  };

  const handleAvatar = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];

      const formData = new FormData();
      formData.append("file", img);

      try {
        const URL = `${process.env.REACT_APP_HIGHTIDE_API_URL}avatar`;
        const response = await fetch(URL, {
          method: "POST",
          body: formData,
        });
        let data = await response.json();
        setAvatar(data.url);
      } catch (err) {
        console.log("error", err);
      }
    }
  };
  useEffect(() => {
    if (user) {
      redirectToDashboard();
    }
  }, [user]);

  return (
    <div className="space-y-10 divide-gray-900/10">
      {hasError && (
        <Alert
          text={
            hasError !== true
              ? hasError
              : "Sorry, there was an error signing up, please try again"
          }
        />
      )}

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Profile
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            This information will be displayed publicly.
          </p>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {props.brand ? (
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Brand Name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder=""
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Website
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        http://
                      </span>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="www.example.com"
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First name or Alias
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="alias"
                      id="alias"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setAlias(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  About {props.brand ? "your company" : "yourself"}
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    onChange={(e) => setAbout(e.target.value)}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Write a few sentences
                  {props.brand
                    ? " to help ambassadors understand what your company does."
                    : " on why brands should hire you as an ambassador. Do not include links or personal information."}
                </p>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Avatar
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  {avatar ? (
                    <div className="w-16 h-16">
                      <img
                        src={avatar}
                        alt="Profile Image"
                        className="object-cover w-full h-full rounded-full"
                      />
                    </div>
                  ) : (
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      fileUploadRef.current.click();
                    }}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Upload
                  </button>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    ref={fileUploadRef}
                    accept="image/png, image/jpeg"
                    onChange={handleAvatar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Your Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Personal information to reach you (not shared publicly).
          </p>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="username"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-x-8 gap-y-8 pt-10 pb-10">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="accept"
              aria-describedby="comments-description"
              name="accept"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onChange={() => setTerms(!terms)}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <span id="accept-description" className="text-gray-500">
              By continuing you agree to our{" "}
              <a href="/terms" target="_blank">
                <u>Terms of Service</u>
              </a>
            </span>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-10 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={formSubmitted}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}
