import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { getRequest, getShareLink, joinClassNames } from "../utils/Utils";
import { useAuth } from "../library/contexts/AuthProvider";
import { Job } from "../models/Job";
import { AmbassadorJobDetails } from "./AmbassdorJobDetails";
import { AcceptedJobWalkthrough } from "./AcceptedJobWalkthrough";
import { useLocation, useNavigate } from "react-router-dom";

export function AmbassadorHome() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [acceptedJobs, setAcceptedJobs] = useState<Job[]>(new Array<Job>());
  const [acceptedJobsLoading, setAcceptedJobsLoading] = useState<boolean>(true);
  const [availableJobs, setAvailableJobs] = useState<Job[]>(new Array<Job>());
  const [jobToShow, setJobToShow] = useState<Job | undefined>();
  const [newLinkCode, setNewLinkCode] = useState<string | undefined>();

  // load the jobs
  useEffect(() => {
    const token = user?.token;
    if (token) {
      loadJobs(token);
    }
  }, [user]);

  // show the job details
  useEffect(() => {
    const words = hash.split("=");
    if (words[0] == "#jobId") {
      const jobId = words[1];
      let job = acceptedJobs.find((job) => job._id == jobId);
      if (!job) {
        job = availableJobs.find((job) => job._id == jobId);
      }
      setJobToShow(job);
    } else {
      // hide all accepted jobs from available jobs
      if (availableJobs.length > 0 && acceptedJobs.length > 0) {
        const acceptedIds = acceptedJobs.reduce(
          (set: Set<string>, job: Job) => {
            set.add(job._id.toString());
            return set;
          },
          new Set<string>()
        );
        const filteredJobs = availableJobs.filter(
          (job) => !acceptedIds.has(job._id.toString())
        );
        if (filteredJobs.length != availableJobs.length) {
          setAvailableJobs(filteredJobs);
        }
      }
    }
  }, [availableJobs, acceptedJobs, hash]);

  function loadJobs(token: string) {
    getRequest("ambassador/accepted_jobs", token).then((response) => {
      setAcceptedJobs(response.jobs);
      setAcceptedJobsLoading(false);
    });
    getRequest("ambassador/available_jobs", token).then((response) => {
      const availableJobs = response.jobs;
      const availableJobsWithBudget = availableJobs.filter(
        (item: any) => item?.budgetCents && item?.budgetCents > 0
      );
      setAvailableJobs(availableJobsWithBudget);
    });
  }

  async function showNewAcceptedJobWalkthrough(linkCode: string) {
    setNewLinkCode(linkCode);
  }

  function hideJobDetails(reload = false) {
    const token = user?.token;
    setNewLinkCode(undefined);
    if (reload && token) {
      loadJobs(token);
    }
    setJobToShow(undefined);
    navigate("#");
  }

  return (
    <>
      <AmbassadorJobDetails
        job={jobToShow}
        onClose={hideJobDetails}
        onAccepted={showNewAcceptedJobWalkthrough}
      />
      <AcceptedJobWalkthrough
        linkCode={newLinkCode}
        onClose={() => hideJobDetails(true)}
      />
      <div className="flex flex-col space-y-8 sm:space-y-4">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Accepted Jobs
            </h3>
          </div>
          <div className="border-t border-gray-100 ">
            <ul role="list" className="divide-y divide-gray-100">
              {acceptedJobsLoading && (
                <li
                  key="loading"
                  className="flex items-center justify-between gap-x-6 px-6 py-5 animate-pulse"
                >
                  <div className="min-w-0">
                    <div className="rounded bg-slate-200 w-40 h-4"></div>
                    <div className="mt-4 rounded bg-slate-200 w-80 h-3"></div>
                    <div className="mt-1 rounded bg-slate-200 w-10 h-3"></div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <div className="hidden rounded-md bg-slate-200 w-20 h-8 sm:block"></div>
                    <div className="relative flex-none">
                      <div className="-m-2.5 block p-2.5 text-gray-500">
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {acceptedJobs.map((job) => (
                <li
                  key={job._id}
                  className="flex items-center justify-between gap-x-6 px-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {job.title}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">{job.description}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Price per Install: $
                        {job.priceCents / 100 || job.price / 100}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Maximum earnings: $
                        {job.budgetCents
                          ? Math.floor(job.budgetCents / 1000)
                          : 0}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Share URL: {getShareLink(job.link)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <button
                      onClick={() =>
                        navigator.clipboard.writeText(getShareLink(job?.link))
                      }
                      className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                      Copy Share URL
                    </button>
                    <a
                      href={`#jobId=${job._id}`}
                      className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                      Details
                      <span className="sr-only">, {job.title}</span>
                    </a>
                    <Menu as="div" className="relative flex-none">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={joinClassNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Edit
                                <span className="sr-only">, {job.title}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={joinClassNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Move
                                <span className="sr-only">, {job.title}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={joinClassNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Delete
                                <span className="sr-only">, {job.title}</span>
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Available Jobs
            </h3>
          </div>
          <div className="border-t border-gray-100 ">
            <ul role="list" className="divide-y divide-gray-100">
              {!availableJobs.length && (
                <li
                  key="loading"
                  className="flex items-center justify-between gap-x-6 px-6 py-5 animate-pulse"
                >
                  <div className="min-w-0">
                    <div className="rounded bg-slate-200 w-40 h-4"></div>
                    <div className="mt-4 rounded bg-slate-200 w-80 h-3"></div>
                    <div className="mt-1 rounded bg-slate-200 w-10 h-3"></div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <div className="hidden rounded-md bg-slate-200 w-20 h-8 sm:block"></div>
                    <div className="relative flex-none">
                      <div className="-m-2.5 block p-2.5 text-gray-500">
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {availableJobs.map((job) => (
                <li
                  key={job._id}
                  className="flex items-center justify-between gap-x-6 px-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {job.title}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">{job.description}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Price per Install: $
                        {job.priceCents / 100 || job.price / 100}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Maximum earnings: $
                        {job.budgetCents
                          ? Math.floor(job.budgetCents / 1000)
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <a
                      href={`#jobId=${job._id}`}
                      className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                      Details
                      <span className="sr-only">, {job.title}</span>
                    </a>
                    <Menu as="div" className="relative flex-none">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={joinClassNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Edit
                                <span className="sr-only">, {job.title}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={joinClassNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Move
                                <span className="sr-only">, {job.title}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={joinClassNames(
                                  active ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                Delete
                                <span className="sr-only">, {job.title}</span>
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
