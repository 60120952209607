import React from "react";
import styles from "./style.module.css";

export default function TermsPage() {
  return (
    <div className={styles.container}>
      <div>
        <p>
          <strong>Terms of Use</strong>
        </p>
        <p>Effective date: April 28, 2023</p>
        <p>
          Welcome to Hightide offered by Turntable.fm, Inc. Please read on to
          learn the rules and restrictions that govern your use of our
          website(s), products, services and applications, including the
          Hightide mobile application (the “Services”). If you have any
          questions, comments, or concerns regarding these terms or the
          Services, please contact us at:
        </p>
        <p>Email: help@hightide.link</p>
        <p>Phone: 404-500-8357</p>
        <p>Address: P.O. Box 454, Brooklyn, NY 11211</p>
        <p>
          These Terms of Use (the “Terms”) are a binding contract between you
          and <strong>TURNTABLE.FM, INC.</strong> (“Turntable,” “we” and “us”).
          Your use of the Services in any way means that you agree to all of
          these Terms, and these Terms will remain in effect while you use the
          Services. These Terms include the provisions in this document as well
          as those in the{" "}
          <a href="https://hightide.link/privacy">Privacy Policy</a>,{" "}
          <a href="https://hightide.link/copyright">Copyright Dispute Policy</a>
          .
          <strong>
            {" "}
            Your use of or participation in certain Services may also be subject
            to additional policies, rules and/or conditions (“Additional
            Terms”), which are incorporated herein by reference, and you
            understand and agree that by using or participating in any such
            Services, you agree to also comply with these Additional Terms.{" "}
          </strong>
        </p>
        <p>
          <strong>Please read these Terms carefully. </strong>They cover
          important information about Services provided to you.{" "}
          <strong>These Terms include information about </strong>
          <a href="#_Will_these_Terms">
            <strong>future changes to these Terms</strong>
          </a>
          <strong>, </strong>
          <a href="#LoL">
            <strong>limitations of liability</strong>
          </a>
          <strong>, </strong>
          <a href="#Arbitration">
            <strong>
              a class action waiver and resolution of disputes by arbitration
              instead of in court
            </strong>
          </a>
          <strong>
            . PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE
            SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE
            FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
          </strong>
        </p>
        <p>
          <strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong> EXCEPT
          FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE{" "}
          <a href="#Arbitration">ARBITRATION AGREEMENT SECTION BELOW</a>, YOU
          AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
          INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
          CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        </p>
        <h1>
          <a id="_Will_these_Terms"></a>1. Will these Terms ever change?
        </h1>
        <p>
          We are constantly trying to improve our Services, so these Terms may
          need to change along with our Services. We reserve the right to change
          the Terms at any time, but if we do, we will place a notice on our
          site located at https://hightide.link/, send you an email, and/or
          notify you by some other means.{" "}
        </p>
        <p>
          If you don’t agree with the new Terms, you are free to reject them;
          unfortunately, that means you will no longer be able to use the
          Services. If you use the Services in any way after a change to the
          Terms is effective, that means you agree to all of the changes.{" "}
        </p>
        <p>
          Except for changes by us as described here, no other amendment or
          modification of these Terms will be effective unless in writing and
          signed by both you and us.{" "}
        </p>
        <h1>2. What about my privacy?</h1>
        <p>
          Turntable takes the privacy of its users very seriously. For the
          current Turntable Privacy Policy, please{" "}
          <a href="https://hightide.link/privacy">click here</a>.
        </p>
        <p>
          <em>Children’s Online Privacy Protection Act</em>
        </p>
        <p>
          The Children’s Online Privacy Protection Act (“COPPA”) requires that
          online service providers obtain parental consent before they knowingly
          collect personally identifiable information online from children who
          are under 13 years of age. We do not knowingly collect or solicit
          personally identifiable information from children under 16 years of
          age; if you are a child under 16 years of age, please do not attempt
          to register for or otherwise use the Services or send us any personal
          information. If we learn we have collected personal information from a
          child under 16 years of age, we will delete that information as
          quickly as possible. If you believe that a child under 16 years of age
          may have provided us personal information, please contact us at
          help@hightide.link.
        </p>
        <h1>3. What are the basics of using Turntable?</h1>
        <p>
          You may be required to sign up for an account, select a password and
          user name (“Turntable User ID”), and provide us with certain
          information or data, such as your contact information. You promise to
          provide us with accurate, complete, and updated registration
          information about yourself. You may not select as your Turntable User
          ID a name that you do not have the right to use, or another person’s
          name with the intent to impersonate that person. You may not transfer
          your account to anyone else without our prior written permission.
        </p>
        <p>
          You represent and warrant that you are an individual of legal age to
          form a binding contract (or if not, you’ve received your parent’s or
          guardian’s permission to use the Services and have gotten your parent
          or guardian to agree to these Terms on your behalf). If you’re
          agreeing to these Terms on behalf of an organization or entity, you
          represent and warrant that you are authorized to agree to these Terms
          on that organization’s or entity’s behalf and bind them to these Terms
          (in which case, the references to “you” and “your” in these Terms,
          except for in this sentence, refer to that organization or entity).
        </p>
        <p>
          You will only use the Services for your own internal, personal,
          non-commercial use, and not on behalf of or for the benefit of any
          third party, and only in a manner that complies with all laws that
          apply to you. If your use of the Services is prohibited by applicable
          laws, then you aren’t authorized to use the Services. We can’t and
          won’t be responsible for your using the Services in a way that breaks
          the law.
        </p>
        <p>
          You will not share your Turntable User ID, account or password with
          anyone, and you must protect the security of your Turntable User ID,
          account, password and any other access tools or credentials. You’re
          responsible for any activity associated with your Turntable User ID
          and account.
        </p>
        <h1>4. What about messaging?</h1>
        <p>
          As part of the Services, you may receive communications through the
          Services, including messages that Turntable sends you (for example,
          via email or SMS). When signing up for the Services, you will receive
          a welcome message and instructions on how to stop receiving messages.{" "}
          <strong>
            By signing up for the Services and providing us with your wireless
            number, you confirm that you want Turntable to send you information
            that we think may be of interest to you, which may include Turntable
            using automated dialing technology to text you at the wireless
            number you provided, and you agree to receive communications from
            Turntable, and you represent and warrant that each person you
            register for the Services or for whom you provide a wireless phone
            number has consented to receive communications from Turntable.
          </strong>{" "}
          You agree to indemnify and hold Turntable harmless from and against
          any and all claims, liabilities, damages (actual and consequential),
          losses and expenses (including attorneys’ fees) arising from or in any
          way related to your breach of the foregoing.
        </p>
        <h1>5. Are there restrictions in how I can use the Services?</h1>
        <p>
          You represent, warrant, and agree that you will not provide or
          contribute anything, including any Content or User Submission (as
          those terms are defined below), to the Services, or otherwise use or
          interact with the Services, in a manner that:
        </p>
        <ol>
          <li>
            infringes or violates the intellectual property rights or any other
            rights of anyone else (including Turntable);
          </li>
          <li>
            violates any law or regulation, including, without limitation, any
            applicable export control laws, privacy laws or any other purpose
            not reasonably intended by Turntable;
          </li>
          <li>
            is dangerous, harmful, fraudulent, deceptive, threatening,
            harassing, defamatory, obscene, or otherwise objectionable;
          </li>
          <li>
            jeopardizes the security of your Turntable User ID, account or
            anyone else’s (such as allowing someone else to log in to the
            Services as you);
          </li>
          <li>
            attempts, in any manner, to obtain the password, account, or other
            security information from any other user;
          </li>
          <li>
            violates the security of any computer network, or cracks any
            passwords or security encryption codes;
          </li>
          <li>
            runs Maillist, Listserv, any form of auto-responder or “spam” on the
            Services, or any processes that run or are activated while you are
            not logged into the Services, or that otherwise interfere with the
            proper working of the Services (including by placing an unreasonable
            load on the Services’ infrastructure);
          </li>
          <li>
            “crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or Content (through use of manual or
            automated means);
          </li>
          <li>copies or stores any significant portion of the Content; or</li>
          <li>
            decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or underlying ideas or information of or relating to the
            Services.
          </li>
        </ol>
        <p>
          A violation of any of the foregoing is grounds for termination of your
          right to use or access the Services.{" "}
        </p>
        <h1>6. What are my rights in the Services?</h1>
        <p>
          The materials displayed or performed or available on or through the
          Services, including, but not limited to, text, graphics, data,
          articles, photos, images, illustrations, User Submissions (as defined
          below) and so forth (all of the foregoing, the “Content”) are
          protected by copyright and/or other intellectual property laws. You
          promise to abide by all copyright notices, trademark rules,
          information, and restrictions contained in any Content you access
          through the Services, and you won’t use, copy, reproduce, modify,
          translate, publish, broadcast, transmit, distribute, perform, upload,
          display, license, sell, commercialize or otherwise exploit for any
          purpose any Content not owned by you, (i) without the prior consent of
          the owner of that Content or (ii) in a way that violates someone
          else’s (including Turntable's) rights.
        </p>
        <p>
          Subject to these Terms, we grant each user of the Services a
          worldwide, non-exclusive, non-sublicensable and non-transferable
          license to use (i.e., to download and display locally) Content solely
          for purposes of using the Services. Use, reproduction, modification,
          distribution or storage of any Content for any purpose other than
          using the Services is expressly prohibited without prior written
          permission from us. You understand that Turntable owns the Services.
          You won’t modify, publish, transmit, participate in the transfer or
          sale of, reproduce (except as expressly provided in this Section),
          create derivative works based on, or otherwise exploit any of the
          Services. The Services may allow you to copy or download certain
          Content, but please remember that even where these functionalities
          exist, all the restrictions in this section still apply.
        </p>
        <h1>
          7. What about anything I contribute to the Services – do I have to
          grant any licenses to Turntable or to other users?
        </h1>
        <p>
          <em>User Submissions</em>
        </p>
        <p>
          Anything you post, upload, share, store, or otherwise provide through
          the Services is your “User Submission”. Some User Submissions may be
          viewable by other users. You are solely responsible for all User
          Submissions you contribute to the Services. You represent that all
          User Submissions submitted by you are accurate, complete, up-to-date,
          and in compliance with all applicable laws, rules and regulations.{" "}
        </p>
        <p>
          You agree that you will not post, upload, share, store, or otherwise
          provide through the Services any User Submissions that: (i) infringe
          any third party's copyrights or other rights (e.g., trademark, privacy
          rights, etc.); (ii) contain sexually explicit content or pornography;
          (iii) contain hateful, defamatory, or discriminatory content or incite
          hatred against any individual or group; (iv) exploit minors; (v)
          depict unlawful acts or extreme violence; (vi) depict animal cruelty
          or extreme violence towards animals; (vii) promote fraudulent schemes,
          multi-level marketing (MLM) schemes, get rich quick schemes, online
          gaming and gambling, cash gifting, work from home businesses, or any
          other dubious money-making ventures; or (viii) that violate any law.
        </p>
        <p>
          <em>Licenses</em>
        </p>
        <p>
          In order to display your User Submissions on the Services, and to
          allow other users to enjoy them (where applicable), you grant us
          certain rights in those User Submissions (see below for more
          information). Please note that all of the following licenses are
          subject to our{" "}
          <a href="https://hightide.link/privacy">Privacy Policy</a> to the
          extent they relate to User Submissions that are also your
          personally-identifiable information.
        </p>
        <p>
          For all User Submissions, you hereby grant Turntable a license to
          translate, modify (for technical purposes, for example, making sure
          your content is viewable on a mobile device as well as a computer) and
          reproduce and otherwise act with respect to such User Submissions, in
          each case to enable us to operate the Services, as described in more
          detail below. This is a license only – your ownership in User
          Submissions is not affected.
        </p>
        <p>
          If you store a User Submission in your own personal Turntable account,
          in a manner that is not viewable by any other user except you (a
          “Personal User Submission”), you grant Turntable the license above, as
          well as a license to display, perform, and distribute your Personal
          User Submission for the sole purpose of making that Personal User
          Submission accessible to you and providing the Services necessary to
          do so.
        </p>
        <p>
          If you share a User Submission in a manner that only certain specified
          users can view (for example, a private message to one or more other
          users) (a “Limited Audience User Submission”), then you grant
          Turntable the licenses above, as well as a license to display,
          perform, and distribute your Limited Audience User Submission for the
          sole purpose of making that Limited Audience User Submission
          accessible to such other specified users, and providing the Services
          necessary to do so. Also, you grant such other specified users a
          license to access that Limited Audience User Submission, and to use
          and exercise all rights in it, as permitted by the functionality of
          the Services.{" "}
        </p>
        <p>
          If you share a User Submission publicly on the Services and/or in a
          manner that more than just you or certain specified users can view, or
          if you provide us (in a direct email or otherwise) with any feedback,
          suggestions, improvements, enhancements, and/or feature requests
          relating to the Services (each of the foregoing, a “Public User
          Submission”), then you grant Turntable the licenses above, as well as
          a license to display, perform, and distribute your Public User
          Submission for the purpose of making that Public User Submission
          accessible to all Turntable users and providing the Services necessary
          to do so, as well as all other rights necessary to use and exercise
          all rights in that Public User Submission in connection with the
          Services and/or otherwise in connection with Turntable's business.
          Also, you grant all other users of the Services a license to access
          that Public User Submission, and to use and exercise all rights in it,
          as permitted by the functionality of the Services.
        </p>
        <p>
          You agree that the licenses you grant are royalty-free, perpetual,
          sublicensable, irrevocable, and worldwide, provided that when you
          delete your Turntable account, we will stop displaying your User
          Submissions (other than Public User Submissions, which may remain
          fully available) to other users (if applicable), but you understand
          and agree that it may not be possible to completely delete that
          content from Turntable's records, and that your User Submissions may
          remain viewable elsewhere to the extent that they were copied or
          stored by other users.{" "}
        </p>
        <p>
          Certain features of the Services allow you to share information with
          others, including through your social networks or other services where
          you have an account (“Third Party Accounts”). When Content is
          authorized for sharing, we will clearly identify the Content you are
          authorized to redistribute and the ways you may redistribute it,
          usually by providing a “share” button on or near the Content. If you
          share information from the Services with others through your Third
          Party Accounts, such as your social networks, you authorize Turntable
          to share that information with the applicable Third Party Account
          provider. Please review the policies of any Third Party Account
          providers you share information with or through for additional
          information about how they may use your information. If you
          redistribute Content, you must be able to edit or delete any Content
          you redistribute, and you must edit or delete it promptly upon our
          request.{" "}
        </p>
        <p>
          Finally, you understand and agree that Turntable, in performing the
          required technical steps to provide the Services to our users
          (including you), may need to make changes to your User Submissions to
          conform and adapt those User Submissions to the technical requirements
          of connection networks, devices, services, or media, and the foregoing
          licenses include the rights to do so.
        </p>
        <h1>
          8. What if I see something on the Services that infringes my
          copyright?
        </h1>
        <p>
          We respect others’ intellectual property rights, and we reserve the
          right to delete or disable Content alleged to be infringing, and to
          terminate the accounts of repeat alleged infringers; to review our
          complete Copyright Dispute Policy and learn how to report potentially
          infringing content,{" "}
          <a href="https://hightide.link/copyright">click here</a>.
        </p>
        <h1>9. Who is responsible for what I see and do on the Services?</h1>
        <p>
          Any information or Content publicly posted or privately transmitted
          through the Services is the sole responsibility of the person from
          whom such Content originated, and you access all such information and
          Content at your own risk, and we aren’t liable for any errors or
          omissions in that information or Content or for any damages or loss
          you might suffer in connection with it. We cannot control and have no
          duty to take any action regarding how you may interpret and use the
          Content or what actions you may take as a result of having been
          exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Services.
          We can’t guarantee the identity of any users with whom you interact in
          using the Services and are not responsible for which users gain access
          to the Services.{" "}
        </p>
        <p>
          You are responsible for all Content you contribute, in any manner, to
          the Services, and you represent and warrant you have all rights
          necessary to do so, in the manner in which you contribute it.
        </p>
        <p>
          The Services may contain links or connections to third-party websites
          or services that are not owned or controlled by Turntable. When you
          access third-party websites or use third-party services, you accept
          that there are risks in doing so, and that Turntable is not
          responsible for such risks.{" "}
        </p>
        <p>
          Turntable has no control over, and assumes no responsibility for, the
          content, accuracy, privacy policies, or practices of or opinions
          expressed in any third-party websites or by any third party that you
          interact with through the Services. In addition, Turntable will not
          and cannot monitor, verify, censor or edit the content of any
          third-party site or service. We encourage you to be aware when you
          leave the Services and to read the terms and conditions and privacy
          policy of each third-party website or service that you visit or
          utilize. By using the Services, you release and hold us harmless from
          any and all liability arising from your use of any third-party website
          or service.{" "}
        </p>
        <p>
          Your interactions with organizations and/or individuals found on or
          through the Services, including payment and delivery of goods or
          services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with
          any online or offline transaction with any of these third parties. You
          agree that Turntable shall not be responsible or liable for any loss
          or damage of any sort incurred as the result of any such dealings.
        </p>
        <p>
          If there is a dispute between participants on this site or Services,
          or between users and any third party, you agree that Turntable is
          under no obligation to become involved. In the event that you have a
          dispute with one or more other users, you release Turntable, its
          directors, officers, employees, agents, and successors from claims,
          demands, and damages of every kind or nature, known or unknown,
          suspected or unsuspected, disclosed or undisclosed, arising out of or
          in any way related to such disputes and/or our Services. You shall and
          hereby do waive California Civil Code Section 1542 or any similar law
          of any jurisdiction, which says in substance: “A general release does
          not extend to claims that the creditor or releasing party does not
          know or suspect to exist in his or her favor at the time of executing
          the release and that, if known by him or her, would have materially
          affected his or her settlement with the debtor or released party.”
        </p>
        <h1>10. Will Turntable ever change the Services?</h1>
        <p>
          We’re always trying to improve our Services, so they may change over
          time. We may suspend or discontinue any part of the Services, or we
          may introduce new features or impose limits on certain features or
          restrict access to parts or all of the Services. We reserve the right
          to remove any Content from the Services at any time, for any reason
          (including, but not limited to, if someone alleges you contributed
          that Content in violation of these Terms), in our sole discretion, and
          without notice.{" "}
        </p>
        <h1>
          <a id="_Do_the_Services"></a>11. Do the Services cost anything?
        </h1>
        <p>
          The Services are currently free, but we reserve the right to charge
          for certain or all Services in the future. We will notify you before
          any Services you are then using begin carrying a fee, and if you wish
          to continue using such Services, you must pay all applicable fees for
          such Services.
        </p>
        <h1>12. What if I want to stop using the Services? </h1>
        <p>
          You’re free to do that at any time by contacting us at
          help@hightide.link; please refer to our{" "}
          <a href="https://hightide.link/privacy">Privacy Policy</a>, as well as
          the licenses above, to understand how we treat information you provide
          to us after you have stopped using our Services.{" "}
        </p>
        <p>
          Turntable is also free to terminate (or suspend access to) your use of
          the Services or your account for any reason in our discretion,
          including your breach of these Terms. Turntable has the sole right to
          decide whether you are in violation of any of the restrictions set
          forth in these Terms.
        </p>
        <p>
          Account termination may result in destruction of any Content
          associated with your account, so keep that in mind before you decide
          to terminate your account.{" "}
        </p>
        <p>
          If you have deleted your account by mistake, contact us immediately at
          help@hightide.link – we will try to help, but unfortunately, we can’t
          promise that we can recover or restore anything.
        </p>
        <p>
          Provisions that, by their nature, should survive termination of these
          Terms shall survive termination. By way of example, all of the
          following will survive termination: any obligation you have to
          indemnify us, any limitations on our liability, any terms regarding
          ownership or intellectual property rights, and terms regarding
          disputes between us, including without limitation the arbitration
          agreement.{" "}
        </p>
        <h1>13. What about Mobile Applications?</h1>
        <p>
          You acknowledge and agree that the availability of our mobile
          application is dependent on the third party stores from which you
          download the application, e.g., the App Store from Apple or the
          Android app market from Google (each an “App Store”). Each App Store
          may have its own terms and conditions to which you must agree before
          downloading mobile applications from such store, including the
          specific terms relating to Apple App Store set forth below. You agree
          to comply with, and your license to use our application is conditioned
          upon your compliance with, such App Store terms and conditions. To the
          extent such other terms and conditions from such App Store are less
          restrictive than, or otherwise conflict with, the terms and conditions
          of these Terms of Use, the more restrictive or conflicting terms and
          conditions in these Terms of Use apply.
        </p>
        <h1>
          14. I use the Turntable App available via the Apple App Store – should
          I know anything about that?
        </h1>
        <p>
          These Terms apply to your use of all the Services, including our iOS
          applications (the “Application”) available via the Apple, Inc.
          (“Apple”) App Store, but the following additional terms also apply to
          the Application:
        </p>
        <ol>
          <li>
            Both you and Turntable acknowledge that the Terms are concluded
            between you and Turntable only, and not with Apple, and that Apple
            is not responsible for the Application or the Content;
          </li>
          <li>
            The Application is licensed to you on a limited, non-exclusive,
            non-transferrable, non-sublicensable basis, solely to be used in
            connection with the Services for your private, personal,
            non-commercial use, subject to all the terms and conditions of these
            Terms as they are applicable to the Services;
          </li>
          <li>
            You will only use the Application in connection with an Apple device
            that you own or control;
          </li>
          <li>
            You acknowledge and agree that Apple has no obligation whatsoever to
            furnish any maintenance and support services with respect to the
            Application;
          </li>
          <li>
            {" "}
            In the event of any failure of the Application to conform to any
            applicable warranty, including those implied by law, you may notify
            Apple of such failure; upon notification, Apple’s sole warranty
            obligation to you will be to refund to you the purchase price, if
            any, of the Application;
          </li>
          <li>
            You acknowledge and agree that Turntable, and not Apple, is
            responsible for addressing any claims you or any third party may
            have in relation to the Application;
          </li>
          <li>
            You acknowledge and agree that, in the event of any third-party
            claim that the Application or your possession and use of the
            Application infringes that third party’s intellectual property
            rights, Turntable, and not Apple, will be responsible for the
            investigation, defense, settlement and discharge of any such
            infringement claim;
          </li>
          <li>
            {" "}
            You represent and warrant that you are not located in a country
            subject to a U.S. Government embargo, or that has been designated by
            the U.S. Government as a “terrorist supporting” country, and that
            you are not listed on any U.S. Government list of prohibited or
            restricted parties;
          </li>
          <li>
            Both you and Turntable acknowledge and agree that, in your use of
            the Application, you will comply with any applicable third-party
            terms of agreement which may affect or be affected by such use; and
          </li>
          <li>
            Both you and Turntable acknowledge and agree that Apple and Apple’s
            subsidiaries are third-party beneficiaries of these Terms, and that
            upon your acceptance of these Terms, Apple will have the right (and
            will be deemed to have accepted the right) to enforce these Terms
            against you as the third-party beneficiary hereof.
          </li>
        </ol>
        <h1>15. What else do I need to know?</h1>
        <p>
          Warranty Disclaimer<em>.</em> Turntable and its licensors, suppliers,
          partners, parent, subsidiaries or affiliated entities, and each of
          their respective officers, directors, members, employees, consultants,
          contract employees, representatives and agents, and each of their
          respective successors and assigns (Turntable and all such parties
          together, the “Turntable Parties”) make no representations or
          warranties concerning the Services, including without limitation
          regarding any Content contained in or accessed through the Services,
          and the Turntable Parties will not be responsible or liable for the
          accuracy, copyright compliance, legality, or decency of material
          contained in or accessed through the Services or any claims, actions,
          suits procedures, costs, expenses, damages or liabilities arising out
          of use of, or in any way related to your participation in, the
          Services. The Turntable Parties make no representations or warranties
          regarding suggestions or recommendations of services or products
          offered or purchased through or in connection with the Services. THE
          SERVICES AND CONTENT ARE PROVIDED BY TURNTABLE (AND ITS LICENSORS AND
          SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR
          ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
          IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.{" "}
        </p>
        <p>
          <a id="LoL"></a>Limitation of Liability. TO THE FULLEST EXTENT ALLOWED
          BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
          (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
          OTHERWISE) SHALL ANY OF THE TURNTABLE PARTIES BE LIABLE TO YOU OR TO
          ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE
          OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST
          PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK
          STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B)
          ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE
          AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER
          BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES,
          SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.{" "}
        </p>
        <p>
          Indemnity. To the fullest extent allowed by applicable law, you agree
          to indemnify and hold the Turntable Parties harmless from and against
          any and all claims, liabilities, damages (actual and consequential),
          losses and expenses (including attorneys’ fees) arising from or in any
          way related to any claims relating to (a) your use of the Services
          (including any actions taken by a third party using your account), and
          (b) your violation of these Terms.{" "}
        </p>
        <p>
          Assignment. You may not assign, delegate or transfer these Terms or
          your rights or obligations hereunder, or your Services account, in any
          way (by operation of law or otherwise) without Turntable's prior
          written consent. We may transfer, assign, or delegate these Terms and
          our rights and obligations without consent.
        </p>
        <p>
          Choice of Law. These Terms are governed by and will be construed under
          the Federal Arbitration Act, applicable federal law, and the laws of
          the State of New York, without regard to the conflicts of laws
          provisions thereof.
        </p>
        <p>
          <a id="Arbitration"></a>Arbitration Agreement<em>. </em>Please read
          the following ARBITRATION AGREEMENT carefully because it requires you
          to arbitrate certain disputes and claims with Turntable and limits the
          manner in which you can seek relief from Turntable. Both you and
          Turntable acknowledge and agree that for the purposes of any dispute
          arising out of or relating to the subject matter of these Terms,
          Turntable's officers, directors, employees and independent contractors
          (“Personnel”) are third-party beneficiaries of these Terms, and that
          upon your acceptance of these Terms, Personnel will have the right
          (and will be deemed to have accepted the right) to enforce these Terms
          against you as the third-party beneficiary hereof.
        </p>
        <p>
          <em>(a) Arbitration Rules; Applicability of Arbitration Agreement</em>
          . The parties shall use their best efforts to settle any dispute,
          claim, question, or disagreement arising out of or relating to the
          subject matter of these Terms directly through good-faith
          negotiations, which shall be a precondition to either party initiating
          arbitration. If such negotiations do not resolve the dispute, it shall
          be finally settled by binding arbitration in New York County, New
          York. The arbitration will proceed in the English language, in
          accordance with the JAMS Streamlined Arbitration Rules and Procedures
          (the “Rules”) then in effect, by one commercial arbitrator with
          substantial experience in resolving intellectual property and
          commercial contract disputes. The arbitrator shall be selected from
          the appropriate list of JAMS arbitrators in accordance with such
          Rules. Judgment upon the award rendered by such arbitrator may be
          entered in any court of competent jurisdiction.{" "}
        </p>
        <p>
          (b) <em>Costs of Arbitration</em>. The Rules will govern payment of
          all arbitration fees. Turntable will pay all arbitration fees for
          claims less than seventy-five thousand ($75,000) dollars. Turntable
          will not seek its attorneys’ fees and costs in arbitration unless the
          arbitrator determines that your claim is frivolous.{" "}
        </p>
        <p>
          (c) <em>Small Claims Court; Infringement</em>. Either you or Turntable
          may assert claims, if they qualify, in small claims court in New York
          County, New York or any United States county where you live or work.
          Furthermore, notwithstanding the foregoing obligation to arbitrate
          disputes, each party shall have the right to pursue injunctive or
          other equitable relief at any time, from any court of competent
          jurisdiction, to prevent the actual or threatened infringement,
          misappropriation or violation of a party's copyrights, trademarks,
          trade secrets, patents or other intellectual property rights.{" "}
        </p>
        <p>
          (d) <em>Waiver of Jury Trial</em>. YOU AND TURNTABLE WAIVE ANY
          CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
          FRONT OF A JUDGE OR JURY. You and Turntable are instead choosing to
          have claims and disputes resolved by arbitration. Arbitration
          procedures are typically more limited, more efficient, and less costly
          than rules applicable in court and are subject to very limited review
          by a court. In any litigation between you and Turntable over whether
          to vacate or enforce an arbitration award, YOU AND TURNTABLE WAIVE ALL
          RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be
          resolved by a judge.
        </p>
        <p>
          (e) <em>Waiver of Class or Consolidated Actions</em>. ALL CLAIMS AND
          DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
          ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
          BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED
          OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER
          OR USER. If however, this waiver of class or consolidated actions is
          deemed invalid or unenforceable, neither you nor Turntable is entitled
          to arbitration; instead all claims and disputes will be resolved in a
          court as set forth in (g) below.<strong> </strong>
        </p>
        <p>
          (f) <em>Opt-out</em>. You have the right to opt out of the provisions
          of this Section by sending written notice of your decision to opt out
          to the following address: P.O. Box 454, Brooklyn, NY 11211 postmarked
          within thirty (30) days of first accepting these Terms. You must
          include (i) your name and residence address, (ii) the email address
          and/or telephone number associated with your account, and (iii) a
          clear statement that you want to opt out of these Terms’ arbitration
          agreement.
        </p>
        <p>
          (g) <em>Exclusive Venue</em>. If you send the opt-out notice in (f),
          and/or in any circumstances where the foregoing arbitration agreement
          permits either you or Turntable to litigate any dispute arising out of
          or relating to the subject matter of these Terms in court, then the
          foregoing arbitration agreement will not apply to either party, and
          both you and Turntable agree that any judicial proceeding (other than
          small claims actions) will be brought in the state or federal courts
          located in, respectively, New York County, New York, or the federal
          district in which that county falls.{" "}
        </p>
        <p>
          (h) <em>Severability</em>. If the prohibition against class actions
          and other claims brought on behalf of third parties contained above is
          found to be unenforceable, then all of the preceding language in this
          Arbitration Agreement section will be null and void. This arbitration
          agreement will survive the termination of your relationship with
          Turntable.
        </p>
        <p>
          Miscellaneous. You will be responsible for paying, withholding,
          filing, and reporting all taxes, duties, and other governmental
          assessments associated with your activity in connection with the
          Services, provided that the Turntable may, in its sole discretion, do
          any of the foregoing on your behalf or for itself as it sees fit. The
          failure of either you or us to exercise, in any way, any right herein
          shall not be deemed a waiver of any further rights hereunder. If any
          provision of these Terms are found to be unenforceable or invalid,
          that provision will be limited or eliminated, to the minimum extent
          necessary, so that these Terms shall otherwise remain in full force
          and effect and enforceable. You and Turntable agree that these Terms
          are the complete and exclusive statement of the mutual understanding
          between you and Turntable, and that these Terms supersede and cancel
          all previous written and oral agreements, communications and other
          understandings relating to the subject matter of these Terms. You
          hereby acknowledge and agree that you are not an employee, agent,
          partner, or joint venture of Turntable, and you do not have any
          authority of any kind to bind Turntable in any respect whatsoever.{" "}
        </p>
        <p>
          Except as expressly set forth in the sections above regarding the
          Apple Application and the arbitration agreement, you and Turntable
          agree there are no third-party beneficiaries intended under these
          Terms.
        </p>
      </div>
    </div>
  );
}
