import React from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth({ children }) {
  const { hasLocalKey } = useAuth();
  const location = useLocation();

  if (hasLocalKey()) {
    return children;
  } else {
    return <Navigate to="/signin" state={{ from: location }} />;
  }
}
