import React, { useState, useEffect } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import { getRequest } from "../utils/Utils";
import Spinner from "../components/Spinner";
import LoggedInNavBarBrands from "../components/LoggedInNavBarBrands";

import { PlusIcon } from "@heroicons/react/20/solid";

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchJobs = async () => {
      const companyId = user?._id;
      if (!companyId || !user) {
        return;
      }

      try {
        setLoading(true);

        getRequest(`get_jobs_for_brand`, user.token).then((response) => {
          setJobs(response.jobs);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchJobs();
  }, [user]);

  // const activeJobs = jobs.filter((job) => job.status === "active");
  // const completedJobs = jobs.filter((job) => job.status === "completed");
  // const archivedJobs = jobs.filter((job) => job.status === "archived");
  // const pausedJobs = jobs.filter((job) => job.status === "paused");

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const renderStatusLabel = (status) => {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
        }}
        className={statusClasses[status]}
      >
        {status}
      </p>
    );
  };

  const statusClasses = {
    active:
      "text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
    completed:
      "text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
    archived:
      "text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
    paused:
      "text-red-700 bg-red-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset",
  };

  const EmptyRoom = () => {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
            Create your first Job
          </p>
        </div>
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-xl tracking-tight text-gray-900 sm:text-xl">
            Jobs are how you pay for Hightide Ambassadors to get installs on
            your app.
          </p>
        </div>
        <div className="flex flex-row items-center justify-center mt-4">
          {!user.disabled && (
            <a href="/brands/create_job">
              <button
                type="button"
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                New Job
              </button>
            </a>
          )}
        </div>
      </div>
    );
  };
  const JobTable = ({ jobs }) => {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base text-lg font-semibold leading-6 text-gray-900">
              Jobs
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the job postings including their title, description,
              application URL, budget, price, and status.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {/* <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Add job
                </button> */}
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Application URL
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Budget
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Price
                      </th>
                      {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                          Impressions
                          </th> */}
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {jobs.map((job) => (
                      <tr key={job.title}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <a href={`/dashboard/brand/job/${job._id}`}>
                            {job.title}
                          </a>
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {job.description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <a
                            href={job.appUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >
                            {job.appUrl}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          ${job.budgetCents / 100 || 0}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          ${job.priceCents / 100 || 0}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {job.impressions || 0}
                            </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {renderStatusLabel(job.status)}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            href={`/brands/edit_job/${job._id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit<span className="sr-only"></span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Navigation */}
      <LoggedInNavBarBrands user={user} />

      {/* Header */}
      {/* <div className="container mx-auto sm:px-6 lg:px-8">
          <h2>Jobs</h2>
          </div> */}
      {/* Job Tables */}
      <div
        style={{
          padding: 16,
        }}
        className="container mx-auto sm:px-6 lg:px-8"
      >
        {!jobs.length && <EmptyRoom />}
        {!!jobs.length && <JobTable jobs={jobs} />}
      </div>
    </div>
  );
};

export default JobList;
