import { useEffect, useRef } from "react";

const hightideUrl = process.env.REACT_APP_HIGHTIDE_URL;

export const getShareLink = (linkCode) => `${hightideUrl}/${linkCode}`;

export const removeLeadingSlash = (str) =>
  str.startsWith("/") ? str.substring(1) : str;

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const FormSubmit = async (path, data, authToken, method = "POST") => {
  let headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  const URL = `${process.env.REACT_APP_HIGHTIDE_API_URL}${removeLeadingSlash(
    path
  )}`;
  return new Promise((resolve, reject) => {
    let ok = false;
    fetch(URL, {
      method: method,
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        ok = response.ok;
        return response.json();
      })
      .then((json) => {
        if (!ok) {
          reject(new Error(json.message));
        } else {
          resolve(json);
        }
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

export const getRequest = async (path, authToken, queryParams) => {
  let headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  let URL = `${process.env.REACT_APP_HIGHTIDE_API_URL}${path}`;
  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");
    URL = queryString.length ? `${URL}?${queryString}` : URL;
  }
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: "GET",
      headers,
    })
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 410) {
            return reject(await response.json());
          }
          throw new Error("Error");
        } else {
          return response.json();
        }
      })
      .then((result) => {
        resolve(result);
      })
      .catch(() => {
        reject();
      });
  });
};

// Validation function to check if the input text exists and is between 1 and 50 characters long
export const validText = (text) => {
  if (
    text &&
    typeof text === "string" &&
    text.length > 0 &&
    text.length <= 250
  ) {
    return true;
  }
  return false;
};

// Validation function to check if the input text exists and is between 1 and 250 characters long
export const validDescription = (text) => {
  if (
    text &&
    typeof text === "string" &&
    text.length > 0 &&
    text.length <= 250
  ) {
    return true;
  }
  return false;
};

// Validation function to check if the input text exists and is a valid URL
export const validWebsite = (text) => {
  if (text && typeof text === "string" && text.length > 0) {
    const urlRegex =
      /^(https?:\/\/)?(([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*\.)+[a-zA-Z]{2,63}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/;
    return urlRegex.test(text);
  }
  return false;
};

export const validPassword = (text) => {
  if (text && typeof text === "string" && text.length > 0) {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,}$/;
    return passwordRegex.test(text);
  }
  return false;
};

export const validEmail = (email) => {
  if (email && typeof email === "string" && email.length > 0) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  return false;
};

export const joinClassNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
export const formatPlatform = (platform) => {
  return platform === "ios" ? "iOS" : "Android";
};
