import React, { useState, useEffect } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import LoggedInNavBarBrands from "../components/LoggedInNavBarBrands";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import { FormSubmit, getRequest } from "../utils/Utils";
import Spinner from "../components/Spinner";
import { useParams } from "react-router-dom";

const BrandEditJob = () => {
  const { user } = useAuth();
  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);

  const { jobId } = useParams();

  const [formData, setFormData] = useState({
    title: job?.title,
    description: job?.description,
    companyId: job?.companyId,
    appUrl: job?.app?.appUrl,
    platform: job?.app?.platform,
    status: job?.status,
    budgetCents: job?.budgetCents,
  });

  useEffect(() => {
    const fetchJob = async () => {
      if (!jobId || !user) {
        return;
      }

      try {
        setLoading(true);

        getRequest(`get_job_with_id/${jobId}`, user.token).then((response) => {
          setJob(response.job);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchJob();
  }, [jobId, user]);

  useEffect(() => {
    setFormData({
      title: job?.title,
      description: job?.description,
      companyId: job?.companyId,
      appUrl: job?.app?.appUrl,
      platform: job?.app?.platform,
      status: job?.status,
      priceCents: job?.priceCents,
      budgetCents: job?.budgetCents,
      jobId: job?._id,
    });
  }, [job]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const authToken = user.token;

    const data = { ...formData, companyId: user?._id };
    try {
      const response = await FormSubmit("edit_job", data, authToken);
      console.log(response);

      // take the user to the brand dashboard
      window.location.href = "/dashboard/brand";
      // optionally display a success message to the user
    } catch (error) {
      console.error(error);
      // optionally display an error message to the user
    }
  };

  return (
    <>
      {user ? <LoggedInNavBarBrands user={user} /> : <LoggedOutNavBar />}
      {loading || !user ? (
        <Spinner />
      ) : (
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-6">
            Edit job
          </h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Title
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="title"
                  id="title"
                  onChange={handleInputChange}
                  value={formData.title}
                  className="block w-full rounded-md border-gray-300 shadow-sm px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Enter the app title"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  onChange={handleInputChange}
                  value={formData.description}
                  rows={3}
                  className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter a brief description of the app"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="appUrl"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                App URL
              </label>
              <div className="mt-2">
                <input
                  type="url"
                  name="appUrl"
                  id="appUrl"
                  onChange={handleInputChange}
                  value={formData.appUrl}
                  className="block w-full rounded-md border-gray-300 shadow-sm px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Enter the app URL"
                />
              </div>
            </div>
            <div>
              <label className="text-sm leading-5 font-medium text-gray-700">
                Platform
              </label>
              <div className="mt-2">
                <select
                  id="platform"
                  name="platform"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handleInputChange}
                  value={formData.platform}
                >
                  <option key={"android"} value={"android"}>
                    Android
                  </option>
                  <option key={"ios"} value={"ios"}>
                    iOS
                  </option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>
              <div className="mt-2">
                <select
                  id="status"
                  name="status"
                  onChange={handleInputChange}
                  value={formData.status}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={formData.status}
                  disabled={user?.disabled || job.status === "inactive"}
                >
                  <option>active</option>
                  <option>paused</option>
                  <option>archived</option>
                  <option>completed</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default BrandEditJob;
