import React, { useState, useEffect } from "react";
import { getRequest } from "../utils/Utils";
import { useAuth } from "../library/contexts/AuthProvider";
import LoggedInNavBarAmbassadors from "../components/LoggedInNavBarAmbassadors";

const PAGE_SIZE = 10;
const formatCents = (amountCents) =>
  (amountCents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return formattedDate;
}

function Pagination({ isFirstPage, isLastPage, onNext, onPrev }) {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="flex flex-1 justify-between sm:justify-end">
        {!isFirstPage && (
          <button
            onClick={onPrev}
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
        )}
        {!isLastPage && (
          <button
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            onClick={onNext}
          >
            Next
          </button>
        )}
      </div>
    </nav>
  );
}

function TransactionsTable({ transactions }) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Job
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Amount Earned
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {transactions.map((transaction) => (
                  <tr key={transaction._id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {formatDate(transaction.createdAt)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {transaction.jobName || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {formatCents(transaction.amountCents)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const AmbassadorProfile = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactions, setTransactions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  async function loadTransactions(token: string, page?: number) {
    setLoading(true);
    let requestedPage = page || currentPage;
    const opts = { size: PAGE_SIZE, page: requestedPage };
    const response = await getRequest(
      "ambassador/job_transactions",
      token,
      opts
    );
    transactions[requestedPage] = response.jobTransactions;
    setTransactions(transactions);
    setTotalTransactions(response.totalCount);
    setLoading(false);
  }
  async function loadNext() {
    const nextPage = currentPage + 1;
    if (transactions[nextPage]) {
      return setCurrentPage(nextPage);
    }
    await loadTransactions(user.token, nextPage);
    setCurrentPage(nextPage);
  }
  async function loadPrev() {
    const prevPage = Math.max(currentPage - 1, 1);
    if (transactions[prevPage]) {
      return setCurrentPage(prevPage);
    }
    await loadTransactions(user.token, prevPage);
    setCurrentPage(prevPage);
  }
  // load the jobs
  useEffect(() => {
    const token = user?.token;
    if (token) {
      loadTransactions(token);
    }
  }, [user]);
  return (
    <div className="min-h-full">
      <LoggedInNavBarAmbassadors />
      <div className="overflow-hidden mx-auto max-w-6xl rounded-lg bg-gray-50 px-32">
        {loading && <div>Loading...</div>}
        {!loading && totalTransactions == 0 && (
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              You have not earned any money yet!
            </p>
            <p className="mt-2 text-xl tracking-tight text-gray-600 sm:text-xl">
              Join a Job and get people to install to get paid.
            </p>
            <div
              href="/dashboard/ambassador"
              className="m-auto mt-4 max-w-xs rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <a href="/dashboard/ambassador">Back to Dashboard</a>
            </div>
          </div>
        )}
        {!loading && totalTransactions > 0 && (
          <>
            <h2 className="px-8 pt-8 text-2xl font-bold tracking-tight text-black sm:text-2xl">
              All Transactions
            </h2>
            {totalTransactions > PAGE_SIZE && (
              <Pagination
                isFirstPage={currentPage == 1}
                isLastPage={
                  currentPage == Math.ceil(totalTransactions / PAGE_SIZE)
                }
                onNext={loadNext}
                onPrev={loadPrev}
              />
            )}
            <TransactionsTable transactions={transactions[currentPage]} />
          </>
        )}
      </div>
    </div>
  );
};

export default AmbassadorProfile;
