import React from "react";
import Dashboard from "../components/Dashboard";

export default function AmbassadorDashboard() {
  return (
    <>
      <Dashboard />
    </>
  );
}
