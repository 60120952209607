import React, { useEffect } from "react";
import { useAuth } from "../library/contexts/AuthProvider";

export default function SignOut() {
  const { user, signout } = useAuth();
  useEffect(() => {
    if (user) {
      signout();
    }
  }, [user, signout]);
  return <></>;
}
