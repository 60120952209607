import React, { useEffect, useState } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import { formatPlatform, getRequest } from "../utils/Utils";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

const Apps = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    const loadApps = async () => {
      const brandId = user?._id;
      if (!brandId || !user) {
        return;
      }

      try {
        setLoading(true);

        const response = await getRequest(`brands/apps`, user.token);

        setApps(response.apps);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadApps();
  }, [user]);
  const copyAppTokenToClipboard = (token) => {
    navigator.clipboard.writeText(token);
  };
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white mb-20 px-32 py-12">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Apps
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all registered mobile apps associated with your brand.
            </p>
          </div>
        </div>
        {!loading && apps.length === 0 && (
          <div className="mt-4 text-gray-500 text-md">
            You have not registered any apps
          </div>
        )}
        {!loading && apps.length > 0 && (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-6">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Platform
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          App Token (&quot;HighTideAppToken&quot;)
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Receiving Pings
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {apps.map((app) => (
                        <tr key={app._id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {app.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {formatPlatform(app.platform)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {app.token}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <>
                              <button
                                type="button"
                                onClick={() =>
                                  copyAppTokenToClipboard(app.token)
                                }
                                className="flex items-center justify-start rounded-lg bg-indigo-600 text-white text-sm px-3 py-2.5"
                              >
                                <ClipboardDocumentListIcon
                                  className="text-sm w-5 h-5 mr-2 text-white"
                                  aria-hidden="true"
                                />
                                Copy
                              </button>
                            </>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {app.receivingPings || "False"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Apps;
