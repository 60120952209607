import React from "react";
import { XCircleIcon, CheckIcon } from "@heroicons/react/20/solid";

export default function Alert(props) {
  const { type } = props;
  if (type === "success") {
    return (
      <div className="rounded-md bg-green-100 p-4 text-left mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckIcon className="h-5 w-5 text-green-700" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-700">
              {props.text || "Success"}
            </h3>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="rounded-md bg-red-50 p-4 text-left mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {props.text || "There was an error, please try again."}
          </h3>
        </div>
      </div>
    </div>
  );
}
