import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "How is this different from traditional advertising?",
    answer:
      "Traditional mobile ads work by interrupting a person when they are playing a game or on social media. This is jarring, has low conversion and can be expensive. Instead of paying large platforms to advertise, we pay ambassadors directly to promote your app. They get to choose how they want to promote your app and therefore gain access to people that might not get targeted by traditional ads.",
  },
  {
    question:
      "What criteria should brand ambassadors consider when selecting apps to promote?",
    answer:
      "It's up to each ambassador to decide which apps they'd like to promote. They should pick ones that they can be a champion of and are excited about.",
  },
  {
    question:
      "How does the platform track downloads attributed to a specific brand ambassador?",
    answer:
      "We give each amabassador a unique link. When people click on that link and install the app, the ambassador gets credit for that install.",
  },
  {
    question: "How do brand ambassadors receive payment for their promotions?",
    answer: "We use Stripe to process and give payments.",
  },
  {
    question: "Is there an approval process?",
    answer:
      "Yes, we need to approve both brands and ambassadors before they can use Hightide. We also monitor for abuse of the platform and will remove access if we detect abuse.",
  },
  {
    question: "Can I promote more than just an iPhone app?",
    answer:
      "Not yet, but soon. We plan to expand what ambassadors can promote.",
  },
  {
    question: "How much can I make as an ambassador?",
    answer:
      "That depends on how good you are at getting people to download apps! For each install attributed to you, you will get $1 USD minus our 15% fee.",
  },
];
const FAQ = () => {
  return (
    <div className="bg-white py-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-4xl text-center font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <ChevronUpIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
