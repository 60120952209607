import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../library/contexts/AuthProvider";
import LoggedInNavBarBrands from "../components/LoggedInNavBarBrands";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { FormSubmit, useInterval } from "../utils/Utils";
import Spinner from "../components/Spinner";

const BrandVerifyPurchase = () => {
  const [polling, setPolling] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const { user } = useAuth();

  async function verify_purchase(session_id) {
    const authToken = user.token;
    try {
      const ret = await FormSubmit(
        "brands/verify_purchase",
        { session_id },
        authToken
      );
      if (ret.captured) {
        setPolling(false);
      }
    } catch (e) {
      setError(e);
    }
  }

  useInterval(() => {
    if (user && searchParams.get("checkout_session_id") && polling) {
      verify_purchase(searchParams.get("checkout_session_id"));
    }
  }, 5000);

  return (
    <>
      {user ? (
        <LoggedInNavBarBrands user={user} isVerifyPurchase />
      ) : (
        <LoggedOutNavBar />
      )}
      <div className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 max-w-md">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      <li>{error}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mx-auto max-w-4xl text-center">
            {polling && (
              <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                Verifying your purchase
                <span className="inline-block ml-4">
                  <Spinner />
                </span>
              </p>
            )}
            {!polling && (
              <>
                <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                  Success!
                </p>
                <p className="mt-2 text-2xl text-white sm:text-3xl">
                  Your credits have been applied.
                </p>
                <a href="/dashboard/brand">
                  <button
                    type="button"
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 mt-2"
                  >
                    Return to Dashboard
                  </button>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandVerifyPurchase;
