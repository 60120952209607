import React, { useState } from "react";
import { FormSubmit, validPassword } from "../utils/Utils";
import { useAuth } from "../library/contexts/AuthProvider";
import Alert from "./Alert";

function PasswordChangeForm() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authToken = user?.token;
    try {
      const ret = await FormSubmit(
        "change_password",
        {
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirm: confirmNewPassword,
        },
        authToken
      );
      if (ret && !ret.success) {
        setSubmitError(ret.error);
        setSubmitSuccess(false);
      } else {
        setSubmitSuccess(true);
        setSubmitError("");
      }
    } catch (e) {
      setSubmitError(
        "Something went wrong, please try again or contact support."
      );
      setSubmitSuccess(false);
      console.log("Error", e);
    }
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    validatePasswords(e, e.target.value);
  };

  const validatePasswords = (e, latestConfirm) => {
    if (
      (latestConfirm && newPassword !== latestConfirm) ||
      (!latestConfirm && newPassword !== confirmNewPassword)
    ) {
      setErrorMessage("Passwords do not match");
    } else if (!validPassword(newPassword)) {
      setErrorMessage(
        "Your password must be at least 8 characters and contain a capital letter, number and symbol"
      );
    } else {
      setErrorMessage("");
    }
  };

  const invalidForm = () => {
    if (oldPassword.length < 1) {
      return true;
    }

    if (newPassword.length < 1) {
      return true;
    }

    if (confirmNewPassword.length < 1) {
      return true;
    }

    if (errorMessage.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      {submitError && <Alert text={submitError} />}
      {submitSuccess && (
        <Alert type="success" text="Your password has been changed." />
      )}

      <label className="text-lg font-semibold">Change Password</label>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-600">
          Old Password
        </label>
        <input
          type="password"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          value={oldPassword}
          onChange={handleOldPasswordChange}
        />
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-600">
          New Password
        </label>
        <input
          type="password"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          value={newPassword}
          onChange={handleNewPasswordChange}
          onBlur={validatePasswords}
        />
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-600">
          Confirm New Password
        </label>
        <input
          type="password"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          onBlur={validatePasswords}
        />
        {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
      </div>
      <button
        type="submit"
        className="w-full py-2 mt-4 font-medium text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
        disabled={invalidForm()}
        style={{
          opacity: invalidForm() ? 0.5 : 1,
          cursor: invalidForm() ? "not-allowed" : "pointer",
        }}
      >
        Change Password
      </button>
    </form>
  );
}

export default PasswordChangeForm;
