import { Dialog, Transition } from "@headlessui/react";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../library/contexts/AuthProvider";
import { Job } from "../models/Job";
import { FormSubmit, getRequest, getShareLink } from "../utils/Utils";

export function AmbassadorJobDetails({
  job,
  onClose,
  onAccepted,
}: {
  job: Job | undefined;
  onClose: (reload?: boolean) => void;
  onAccepted: (linkCode: string) => void;
}) {
  const { user } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [impressions, setImpressions] = useState<number>(-1);
  const [installs, setInstalls] = useState<number>(-1);

  useEffect(() => {
    const getImpressions = () => {
      const link = job?.link;
      if (!link || !user) {
        return;
      }
      getRequest(
        "ambassador/impressions?jobId=" + encodeURIComponent(job._id),
        user.token
      ).then(
        (response) => {
          setImpressions(response.impressions);
        },
        (error) => {
          console.error(error);
        }
      );
      getRequest(
        "ambassador/installs?jobId=" + encodeURIComponent(job._id),
        user.token
      ).then(
        (response) => {
          setInstalls(response.installs);
        },
        (error) => {
          console.error(error);
        }
      );
    };

    if (job) {
      setOpen(true);
      getImpressions();
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, user]);

  const acceptJob = async () => {
    FormSubmit("ambassador/accept_job", { jobId: job?._id }, user?.token)
      .then((response) => {
        console.log("Accepted job.");
        onAccepted(response.link as string);
      })
      .catch(() => {
        console.error("Failed to accept job.");
        onClose(true);
      });
  };

  const leaveJob = async () => {
    FormSubmit("ambassador/leave_job", { link: job?.link }, user?.token)
      .then(() => {
        console.log("Left job.");
      })
      .catch(() => {
        console.error("Failed to leave job.");
      })
      .finally(() => {
        onClose(true);
      });
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(getShareLink(job?.link));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 max-w-prose md:w-full">
                <div className="px-4 py-6 sm:px-6">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    Job Details
                  </h3>
                </div>
                <div className="border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        Title
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {job?.title}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        Description
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {job?.description}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">
                        Price per install
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        ${(job?.priceCents ? job.priceCents : 0) / 100}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-900">App</dt>
                      <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                        <a
                          href={job?.appUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          {job?.appUrl}
                        </a>
                      </dd>
                    </div>
                    {job?.link && (
                      <>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Share Link
                          </dt>
                          <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0 flex flex-row gap-4">
                            <input
                              type="url"
                              name="link"
                              id="link"
                              className="grow rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={getShareLink(job.link)}
                              readOnly={true}
                            />
                            <button
                              type="button"
                              onClick={copyShareLink}
                              className="grow-0 rounded-full bg-indigo-600 p-3 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              <ClipboardDocumentListIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Impressions
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {impressions != -1 ? impressions : "Loading"}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-900">
                            Installs
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {installs != -1 ? installs : "Loading"}
                          </dd>
                        </div>
                      </>
                    )}
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => onClose()}
                      >
                        Close
                      </button>
                      {!job?.link && (
                        <>
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3"
                            onClick={() => acceptJob()}
                          >
                            Accept Job
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                            onClick={() => onClose()}
                          >
                            Hide Job
                          </button>
                        </>
                      )}
                      {job?.link && (
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3"
                          onClick={() => leaveJob()}
                        >
                          Leave Job
                        </button>
                      )}
                    </div>
                  </dl>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
