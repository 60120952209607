import React, { useEffect } from "react";
import Hero from "../components/Hero";
import FrequentlyAskedQuestions from "../components/FAQ.js";
import { useAuth } from "../library/contexts/AuthProvider";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";
import FeatureImage1 from "../images/FeatureImage1.svg";
import FeatureImage2 from "../images/FeatureImage2.svg";

const brandFeatures = [
  {
    name: "Friend Networks",
    description:
      "Gain access to thousands of ambassadors that promote your app to their own networks of friends.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Data",
    description:
      "We provide real-time insights on how many impressions and installs ambassadors are able to drive.",
    href: "#",
    icon: LockClosedIcon,
  },
  {
    name: "Easy Integration",
    description: "Just add our cocoapod to your app and you're ready to go.",
    href: "#",
    icon: ArrowPathIcon,
  },
];

const ambassadorFeatures = [
  {
    name: "Earn Cash",
    description:
      "Browse through apps and decide which ones you'd like to help grow. Get paid for each referral installed.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Easy to Share",
    description:
      "We give you a unique link that you can share anywhere, even offline.",
    href: "#",
    icon: ArrowPathIcon,
  },
  {
    name: "No Minimums",
    description: "Get payouts for any amount you earn, no minimum payout.",
    href: "#",
    icon: LockClosedIcon,
  },
];

export default function Index() {
  const { user, redirectToDashboard } = useAuth();
  useEffect(() => {
    if (user) {
      redirectToDashboard();
    }
  }, [user]);
  return (
    <div className="bg-white">
      <Hero />
      <div className="bg-white py-0">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-32 lg:px-8 lg:py-0">
            <div className="mx-auto lg:max-w-none">
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                For Brands
              </h2>
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 mt-10 lg:max-w-none">
                {brandFeatures.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-lg font-normal leading-7 text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-0 flex flex-auto flex-col text-base leading-7 text-black/80">
                      <p className="flex-auto">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow lg:max-w-[50%]">
              <img src={FeatureImage1} />
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-6 pb-24 sm:py-32 flex flex-col-reverse lg:flex lg:flex-row lg:gap-x-32 lg:px-8 lg:pt-24">
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow lg:max-w-[50%]">
              <img src={FeatureImage2} />
            </div>
            <div className="mx-auto lg:max-w-none">
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                For Ambassadors
              </h2>
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 mt-10 lg:max-w-none">
                {ambassadorFeatures.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-lg font-normal leading-7 text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-0 flex flex-auto flex-col text-base leading-7 text-black/80">
                      <p className="flex-auto">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
      <a name="faq">
        <FrequentlyAskedQuestions />
      </a>
      <div className="bg-black py-0 mt-16">
        <div className="mx-auto max-w-7xl py-0 sm:px-6 sm:py-0 lg:px-8">
          <div className="relative isolate overflow-hidden px-6 py-24 text-center sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {"Ready to Join?"}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              {
                "We are a passionate community of builders and ambassadors who are tired of the traditional ways apps get advertised. Join us and let's promote a better way."
              }
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/signup"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Sign Up for Free
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
