/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../library/contexts/AuthProvider";
import { FormSubmit } from "../utils/Utils";
import Spinner from "./Spinner";
import {
  ClockIcon,
  CogIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { AmbassadorHome } from "./AmbassadorHome";
import LoggedInNavBarAmbassadors from "./LoggedInNavBarAmbassadors";

const navigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: true },
  { name: "History", href: "#", icon: ClockIcon, current: false },
];
const secondaryNavigation = [
  { name: "Settings", href: "/ambassador/profile", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
  { name: "Privacy", href: "#", icon: ShieldCheckIcon },
  { name: "Sign out", href: "/signout", icon: ArrowRightOnRectangleIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MainDashboard({ user, storeUser }) {
  const [initializingBankRedirect, setInitializingBankRedirect] =
    useState(false);
  const [checkingBankInfo, setCheckingBankInfo] = useState(false);
  const [error, setError] = useState(null);
  const [stripeNeedsMoreInfo, setStripeNeedsMoreInfo] = useState(false);
  const [searchParams] = useSearchParams();

  async function setupBankInfo() {
    if (initializingBankRedirect) {
      return;
    }
    try {
      setInitializingBankRedirect(true);
      const authToken = user.token;
      const ret = await FormSubmit("ambassador/paymentConnect", {}, authToken);
      if (ret && ret.redirect_to) {
        window.location = ret.redirect_to;
      } else {
        // TODO: show error
      }
    } catch (e) {
      // TODO: show error
    } finally {
      setInitializingBankRedirect(false);
    }
  }

  function renderAccountStatus() {
    let block = (
      <>
        <dt className="sr-only">Account status</dt>
        <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
          <CheckCircleIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
            aria-hidden="true"
          />
          Verified account
        </dd>
      </>
    );
    if (!user.hasBankInfo) {
      block = (
        <>
          <dt className="sr-only">Account status</dt>
          <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
            <ExclamationTriangleIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
              aria-hidden="true"
            />
            Missing Bank Info
          </dd>
        </>
      );
    }
    return block;
  }
  function renderAccountDetails() {
    let block = AmbassadorHome();
    if (!user.hasBankInfo) {
      if (stripeNeedsMoreInfo) {
        return (
          <div className="flex flex-col items-center">
            <div className="max-w-md flex flex-col items-center">
              <div className="mt-4 rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      It looks like Stripe needs more of your information
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <a
                        href="#"
                        className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                        onClick={setupBankInfo}
                      >
                        Resubmit with Stripe
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      // NOTE: if !user.hasBankInfo, then the user either:
      // 1. has not attempted to give Stripe their information
      // 2. partially attempted to their info to stripe
      // 3. gave their info to Stripe but we have not synced with them yet
      //
      // In case 2 & 3, we want to poll Stripe continuously. Stripe
      // always redirects us to our configured `return_url` and not the
      // `refresh_url`. Bad.
      let buttonText = "Setup Account";
      let description =
        "In order to get jobs on Hightide, you need to tell us how to pay you!";
      let buttonDisabled = initializingBankRedirect;
      let reLink = false;

      // NOTE: if you tap "return to Hightide" after not entering payment details, this is added. this corresponds to the return_url.
      if (
        searchParams.get("bankLinkInfo") ||
        searchParams.get("bankLinkRefresh")
      ) {
        buttonText = initializingBankRedirect ? (
          <Spinner color="indigo" />
        ) : (
          "Resubmit to Stripe"
        );
        description =
          "Please wait while we ensure Stripe has all your information";
        reLink = true;
      } else if (initializingBankRedirect) {
        buttonText = <Spinner />;
      }

      block = (
        <div className="flex flex-col items-center">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{error}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="max-w-md flex flex-col items-center">
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <div className="flex text-sm font-medium text-yellow-800">
                    Bank Information Needed
                    {reLink && (
                      <div className="ml-4">
                        <Spinner color="yellow" />
                      </div>
                    )}
                  </div>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
            {reLink && (
              <div className="mt-4 rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      Forgot to enter your payment details?
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <a
                        href="#"
                        className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                        onClick={setupBankInfo}
                      >
                        {buttonText}
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!reLink && (
              <button
                disabled={buttonDisabled}
                onClick={setupBankInfo}
                type="button"
                className={classNames(
                  initializingBankRedirect
                    ? "bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600"
                    : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                  "mt-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                )}
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
      );
    }
    return block;
  }
  async function checkBankInfo() {
    // see if they have given their bank info to stripe which can
    // happen asynchronously
    try {
      setError(null);
      setCheckingBankInfo(true);
      const authToken = user.token;
      const { success, data } = await FormSubmit(
        "ambassador/verifyBankInfo",
        {},
        authToken
      );
      if (success && data.user) {
        // NOTE: store any user fields that have changed
        storeUser({ ...user, ...data.user });
      } else if (data.stripe_needs_more) {
        // NOTE:
        // if we get back "Stripe needs more of your information" from
        // the API, then we know to stop polling.
        setStripeNeedsMoreInfo(true);
      }
    } catch (e) {
      setError(
        "We could not determine if Stripe has you necessary bank info. Please contact our support team"
      );
    } finally {
      setCheckingBankInfo(false);
    }
  }
  useEffect(() => {
    let intervalId;
    if (
      (!user.hasBankInfo && searchParams.get("bankLinkInfo")) ||
      searchParams.get("bankLinkRefresh")
    ) {
      intervalId = setInterval(() => {
        if (user.hasBankInfo || stripeNeedsMoreInfo) {
          clearInterval(intervalId);
        } else {
          checkBankInfo();
        }
      }, 30000);
    }
    return () => (intervalId ? clearInterval(intervalId) : {});
  }, [user]);

  useEffect(() => {
    if (user && !user.hasBankInfo && !checkingBankInfo) {
      checkBankInfo();
    }
    return () => {};
  }, []);

  return (
    <main className="flex-1 pb-8">
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="min-w-0 flex-1">
              {/* Profile */}
              <div className="flex items-center">
                <img
                  className="hidden h-16 w-16 rounded-full sm:block object-cover"
                  src={user.avatar}
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <img
                      className="h-16 w-16 rounded-full sm:hidden object-cover"
                      src={user.avatar}
                      alt=""
                    />
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      Hello, {user.alias}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    {renderAccountStatus()}
                  </dl>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <a
                      href="/ambassador/transactions"
                      className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                      onClick={console.log}
                    >
                      {"Lifetime Earnings: $" +
                        (Number(user.lifetimeTotalAmountCents) / 100).toFixed(
                          2
                        )}
                    </a>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 mt-8">
        {renderAccountDetails()}
      </div>
    </main>
  );
}
export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, storeUser } = useAuth();

  return (
    <>
      <div className="min-h-full">
        <LoggedInNavBarAmbassadors />
        <div className="flex flex-1 flex-col">
          {!user && <div>Loading...</div>}
          {user && <MainDashboard user={user} storeUser={storeUser} />}
        </div>
      </div>
    </>
  );
}
