import React from "react";
import "./App.css";

import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Page Imports
import Index from "./pages/Index";
// import SignIn from "./pages/SignIn";
import SignInAmbassador from "./pages/SignInAmbassador";
import SignInBrand from "./pages/SignInBrand";
import SignUp from "./pages/SignUp";
import BrandSignUp from "./pages/BrandSignUp";
import BrandDashboard from "./pages/BrandDashboard";
import BrandEditJob from "./pages/BrandEditJob";
import AmbassadorSignUp from "./pages/AmbassadorSignUp";
import AmbassadorDashboard from "./pages/AmbassadorDashboard";
import JobDashboard from "./pages/JobDashboard";
import BrandCreateJob from "./pages/BrandCreateJob";
import Success from "./pages/Success";
import ForgotPasswordSuccess from "./pages/ForgotPasswordSuccess";
import BrandAddCredit from "./pages/BrandAddCredit";
import BrandVerifyPurchase from "./pages/BrandVerifyPurchase";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Copyright from "./pages/Copyright";
import InstallInstructions from "./pages/InstallInstructions";
import AmbassadorPublicProfile from "./pages/AmbassadorPublicProfile";

import RequireAuth from "./utils/RequireAuth"; // Note: this doesn't validate, just sees if it looks like auth
// This is because auth takes a second and is async and would flash every time

// Provider Imports
import { AuthProvider } from "./library/contexts/AuthProvider";

// Component Imports
import Footer from "./components/Footer";
import ResetPassword from "./pages/ResetPassword";
import JobLink from "./components/JobLink";
import SignOut from "./pages/SignOut";
import AmbassadorProfile from "./pages/AmbassadorProfile";
import AmbassadorTransactions from "./pages/AmbassadorTransactions";
import BrandTransactions from "./pages/BrandTransactions";
import BrandProfile from "./pages/BrandProfile";

function App() {
  return (
    <>
      <Helmet>
        <html className="h-full bg-gray-50" />
        <body className="h-full" />
        <title>Hightide</title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-VGNK8BE2PT"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-VGNK8BE2PT');`}
        </script>
      </Helmet>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            {/** TODO: Index, SignIn, SignUp, BrandSignUp, AmbassadorSignUp should redirect to the dashboard of logged-in user */}
            <Route path="/" element={<Index />} />
            <Route path="/signinAmbassador" element={<SignInAmbassador />} />
            <Route path="/signinBrand" element={<SignInBrand />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignInAmbassador />} />
            <Route path="/brand_signup" element={<BrandSignUp />} />
            <Route path="/ambassador_signup" element={<AmbassadorSignUp />} />
            <Route
              path="/ambassador/profile"
              element={
                <RequireAuth>
                  <AmbassadorProfile />
                </RequireAuth>
              }
            />

            <Route
              path="/ambassador/transactions"
              element={
                <RequireAuth>
                  <AmbassadorTransactions />
                </RequireAuth>
              }
            />

            <Route path="/signout" element={<SignOut />} />

            {/** TODO: combine with forgot_password_success below */}
            <Route path="/resetpassword" element={<ResetPassword />} />

            {/** TODO: We don't support having a user be both a brand and an ambassador. Only one of these is addressable to the logged-in user */}
            <Route
              path="/dashboard/ambassador"
              element={
                <RequireAuth>
                  <AmbassadorDashboard />
                </RequireAuth>
              }
            />

            {/** TODO: only addressable to the brand */}
            <Route
              path="/brands/profile"
              element={
                <RequireAuth>
                  <BrandProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/brands/create_job"
              element={
                <RequireAuth>
                  <BrandCreateJob />
                </RequireAuth>
              }
            />
            <Route
              path="/brands/edit_job/:jobId"
              element={
                <RequireAuth>
                  <BrandEditJob />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/brand"
              element={
                <RequireAuth>
                  <BrandDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/brand/job/:jobId"
              element={
                <RequireAuth>
                  <JobDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/brand/add_credit"
              element={
                <RequireAuth>
                  <BrandAddCredit />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/brand/verify_purchase"
              element={<BrandVerifyPurchase />}
            />
            <Route
              path="/brand/transactions"
              element={
                <RequireAuth>
                  <BrandTransactions />
                </RequireAuth>
              }
            />

            {/** TODO: remove after login success work */}
            <Route path="/success" element={<Success />} />

            {/** TODO: Does this need its own page? */}
            <Route
              path="/forgot_password_success"
              element={<ForgotPasswordSuccess />}
            />
            <Route path="*" element={<JobLink />} />

            {/** Support Pages **/}
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/copyright" element={<Copyright />} />
            <Route path="/install" element={<InstallInstructions />} />

            {/** Basic Ambassador Profile page for Stripe connect**/}
            <Route
              path="/user/:ambassadorId"
              element={<AmbassadorPublicProfile />}
            />
          </Routes>
          <Footer />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
