import React from "react";
import LoggedOutNavBar from "../components/LoggedOutNavBar";
import SignUpForm from "../components/SignUpForm";

export default function BrandSignUp() {
  return (
    <>
      <LoggedOutNavBar />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-10">
        <div className="mx-auto max-w-3xl">
          <SignUpForm brand={true} />
        </div>
      </div>
    </>
  );
}
